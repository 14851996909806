import Vue from "vue";
import Vuex from "vuex";
import { ORG_ROLE } from "@/core/organization";
import { loadFilterSettings, saveFilterSettings } from "@/features/transportsFilterSettings/transportsFilterSettings";
import { updaterPlugin } from "@/store/updaterPlugin";
import { buildAgreementsModule } from "@/features/agreements/store";
import { buildAuthModule } from "@/features/auth/store";
import { buildCarriersModule } from "@/features/carriers/store";
import { buildErrorMessagesModule } from "@/features/errorMessages/store";
import { buildFeaturesModule } from "@/features/featureToggle/store";
import { buildLocationArrivalsModule } from "@/features/locationArrivals/store";
import { buildNotificationsModule } from "@/features/notifications/store";
import { buildOrganizationModule } from "@/features/organization/store";
import { buildSearchTransportsModule } from "@/features/search/store";
import { buildShippersModule } from "@/features/shippers/store";
import { buildTransportDetailsModule } from "@/features/transportDetails/store";
import { buildTransportColumnsSettingsModule } from "@/features/transportTableColumnsSettings/store";
import {
  buildTransportsModule,
  buildCancelledTransportsModule,
  buildCompletedTransportsModule,
  buildActionsRequiredModule,
} from "@/features/transports/store";
import { buildVersionCheckModule } from "@/features/versionCheck/store";
import { PAGES } from "@/router/pageNames";
import {
  getActionRequiredTransports,
  getAreaArrivedTransports,
  getArrivedTransports,
  getCancelledTransports,
  getCompletedTransports,
  getGpsLostTransports,
  getGpsLowTransports,
  getGpsMissingTransports,
  getIncomingTransports,
  getPresumablyIncomingTransports,
  getManuallyCompletedTransports,
  getOngoingTransports,
  getPresumablyOngoingTransports,
  getOverdueTransports,
  getPlannedTransports,
  getTrackingIdMissingTransports,
  getWaypointConfirmations,
} from "@/features/transports/api";
import { getFeatures } from "@/features/featureToggle/api";
import { buildWatchlistModule } from "@/features/watchlist/store";

Vue.use(Vuex);

const EVENT = {
  CURRENT_PAGE_CHANGED: "CURRENT_PAGE_CHANGED",
  FILTERS_CHANGED: "FILTERS_CHANGED",
  LAST_VIEWED_TRANSPORT_CHANGED: "LAST_VIEWED_TRANSPORT_CHANGED",
  NAV_MENU_TOGGLED: "NAV_MENU_TOGGLED",
  TABLE_SCROLL_POSITION_CHANGED: "TABLE_SCROLL_POSITION_CHANGED",
};

const defaultState = () => ({
  currentPage: "",
  ui: {
    lastViewedTransportId: "",
    showNavMenu: false,
    transportTableFilters: loadFilterSettings(),
    transportTableScrollPosition: { page: "", position: null },
  },
});

const mutations = {
  [EVENT.CURRENT_PAGE_CHANGED](state, page) {
    state.currentPage = page;
  },

  [EVENT.FILTERS_CHANGED](state, filters) {
    state.ui.transportTableFilters = filters;
  },

  [EVENT.LAST_VIEWED_TRANSPORT_CHANGED](state, transportId) {
    state.ui.lastViewedTransportId = transportId;
  },

  [EVENT.NAV_MENU_TOGGLED](state) {
    state.ui.showNavMenu = !state.ui.showNavMenu;
  },

  [EVENT.TABLE_SCROLL_POSITION_CHANGED](state, { page, position }) {
    state.ui.transportTableScrollPosition = {
      page,
      position,
    };
  },
};

const actions = {
  getAllTransports({ getters, state }) {
    this.dispatch("watchlist/fetch");
    this.dispatch("actionRequiredTransports/fetch");
    this.dispatch("areaArrivedTransports/fetch");
    this.dispatch("arrivedTransports/fetch");
    this.dispatch("completedTransports/fetch");
    this.dispatch("gpsLostTransports/fetch");
    this.dispatch("gpsLowTransports/fetch");
    this.dispatch("gpsMissingTransports/fetch");
    this.dispatch("waypointConfirmations/fetch");
    this.dispatch("incomingTransports/fetch");
    this.dispatch("presumablyIncomingTransports/fetch");
    this.dispatch("manuallyCompletedTransports/fetch");
    this.dispatch("ongoingTransports/fetch");
    this.dispatch("presumablyOngoingTransports/fetch");
    this.dispatch("overdueTransports/fetch");
    this.dispatch("plannedTransports/fetch");
    this.dispatch("trackingIdMissingTransports/fetch");
    this.dispatch("cancelledTransports/fetch");

    if (getters.isUserShipper) {
      this.dispatch("carriers/fetch");
      this.dispatch("carriers/fetchCarriersWithTransports");
    }
    if (getters.isUserCarrier) {
      this.dispatch("shippers/fetchShippersWithTransports");
    }

    if (state.searchResults.searchText) {
      this.dispatch("searchResults/fetch");
    }
  },

  setCurrentPage({ commit }, page) {
    commit(EVENT.CURRENT_PAGE_CHANGED, page);
  },

  setFilters({ commit, state }, filters) {
    let newFilters = {};

    if (filters !== null) {
      newFilters = {
        ...state.ui.transportTableFilters,
        ...filters,
      };
    }

    saveFilterSettings(newFilters);
    commit(EVENT.FILTERS_CHANGED, newFilters);
  },

  setLastViewedTransport({ commit }, transportId) {
    commit(EVENT.LAST_VIEWED_TRANSPORT_CHANGED, transportId);
  },

  setTableScrollPosition({ commit }, { page, position }) {
    commit(EVENT.TABLE_SCROLL_POSITION_CHANGED, { page, position });
  },

  toggleNavMenu({ commit }) {
    commit(EVENT.NAV_MENU_TOGGLED);
  },
};

const getters = {
  isUserCarrier(state, getters) {
    return getters.profile?.role === ORG_ROLE.CARRIER;
  },

  isUserShipper(state, getters) {
    return getters.profile?.role === ORG_ROLE.SHIPPER;
  },

  async isAdmin() {
    const enabled = await getFeatures();
    return enabled.includes("adminUi");
  },

  profile(state) {
    const idToken = store.getters["auth/idToken"];

    if (!idToken) {
      return {};
    }

    return {
      email: idToken.email,
      familyName: idToken.family_name,
      givenName: idToken.given_name,
      nickname: idToken.nickname,
      picture: idToken.picture,
      role: state.organization?.orgRole,
    };
  },

  transportTableFilters(state) {
    return state.ui.transportTableFilters;
  },
};

const modules = {
  watchlist: buildWatchlistModule(),
  actionRequiredTransports: buildActionsRequiredModule({
    requestTransports: getActionRequiredTransports,
  }),
  agreements: buildAgreementsModule(),
  areaArrivedTransports: buildCompletedTransportsModule({
    requestTransports: getAreaArrivedTransports,
  }),
  arrivedTransports: buildCompletedTransportsModule({
    requestTransports: getArrivedTransports,
  }),
  auth: buildAuthModule(),
  cancelledTransports: buildCancelledTransportsModule({
    requestTransports: getCancelledTransports,
  }),
  carriers: buildCarriersModule(),
  completedTransports: buildCompletedTransportsModule({
    requestTransports: getCompletedTransports,
  }),
  errorMessages: buildErrorMessagesModule(),
  features: buildFeaturesModule(),
  gpsLostTransports: buildActionsRequiredModule({
    requestTransports: getGpsLostTransports,
  }),
  gpsLowTransports: buildActionsRequiredModule({
    requestTransports: getGpsLowTransports,
  }),
  gpsMissingTransports: buildActionsRequiredModule({
    requestTransports: getGpsMissingTransports,
  }),
  incomingTransports: buildTransportsModule({
    requestTransports: getIncomingTransports,
  }),
  presumablyIncomingTransports: buildTransportsModule({
    requestTransports: getPresumablyIncomingTransports,
  }),
  locationArrivals: buildLocationArrivalsModule(),
  manuallyCompletedTransports: buildCompletedTransportsModule({
    requestTransports: getManuallyCompletedTransports,
  }),
  notifications: buildNotificationsModule(),
  ongoingTransports: buildTransportsModule({
    requestTransports: getOngoingTransports,
  }),
  presumablyOngoingTransports: buildTransportsModule({
    requestTransports: getPresumablyOngoingTransports,
  }),
  organization: buildOrganizationModule(),
  overdueTransports: buildCompletedTransportsModule({
    requestTransports: getOverdueTransports,
  }),
  plannedTransports: buildTransportsModule({
    requestTransports: getPlannedTransports,
  }),
  searchResults: buildSearchTransportsModule(),
  shippers: buildShippersModule(),
  trackingIdMissingTransports: buildActionsRequiredModule({
    requestTransports: getTrackingIdMissingTransports,
  }),
  transportDetails: buildTransportDetailsModule(),
  transportTableColumnsSettings: buildTransportColumnsSettingsModule(),
  versionCheck: buildVersionCheckModule(),
  waypointConfirmations: buildActionsRequiredModule({
    requestTransports: getWaypointConfirmations,
  }),
};

const ONE_MINUTE_IN_MS = 1000 * 60;
const updateAllowed = (store) => Boolean(store.state.organization.orgRole);

const plugins = [
  updaterPlugin({
    action: "notifications/getNotifications",
    pages: [`!${PAGES.TRANSPORT_TRACKING}`, `!${PAGES.NO_ORGANIZATION}`],
    pollingIntervalMs: ONE_MINUTE_IN_MS,
    updateAllowed,
  }),
  updaterPlugin({
    action: "getAllTransports",
    pages: [`!${PAGES.TRANSPORT_TRACKING}`, `!${PAGES.NO_ORGANIZATION}`],
    pollingIntervalMs: ONE_MINUTE_IN_MS,
    updateAllowed,
  }),
  updaterPlugin({
    action: "transportDetails/refresh",
    pages: [PAGES.TRANSPORT_DETAILS],
    pollingIntervalMs: ONE_MINUTE_IN_MS,
    updateAllowed,
  }),
  updaterPlugin({
    action: "versionCheck/getVersion",
    pages: [`!${PAGES.TRANSPORT_TRACKING}`],
    pollingIntervalMs: ONE_MINUTE_IN_MS * 15,
  }),
  updaterPlugin({
    action: "agreements/getAgreements",
    pages: [`!${PAGES.TRANSPORT_TRACKING}`, `!${PAGES.NO_ORGANIZATION}`],
    pollingIntervalMs: ONE_MINUTE_IN_MS,
    updateAllowed,
  }),
];

const store = new Vuex.Store({
  state: defaultState(),
  mutations,
  actions,
  getters,
  modules,
  plugins,
});

export default store;

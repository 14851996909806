import Vue from "vue";

/**
 * Used to set a dynamic scroll bar to the
 * transport details tabs.
 * It sets the height of the element based on
 * the size of the page and how far from the
 * top the element is. Might not be super
 * useful in other use cases.
 */
Vue.directive("detailsScroll", {
  inserted(element) {
    setHeight(element);
  },

  componentUpdated(element) {
    setHeight(element);
  },
});

function setHeight(element) {
  // when the element is hidden `getBoundingClientRect().top` is 0,
  // to get a correct value, we try the `parentElement`s
  let currentElement = element;
  while (currentElement.getBoundingClientRect().top === 0 && currentElement.parentElement) {
    currentElement = currentElement.parentElement;
  }

  const heightOffset = currentElement.getBoundingClientRect().top;
  element.style.height = `calc(100vh - ${heightOffset}px`;
  element.style.overflow = "auto";
}

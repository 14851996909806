import { TRANSPORT_PAGES } from "@/router/pageNames";
import {
  loadColumnsOrder,
  loadColumnsVisibility,
  saveColumnsOrder,
  saveColumnsVisibilityForAll,
  saveColumnsVisibilityForPage,
} from "@/features/transportTableColumnsSettings";

export const EVENT = {
  SAVE_ORDER_TRIGGERED: "SAVE_ORDER_TRIGGERED",
  SAVE_SETTINGS_TRIGGERED: "SAVE_SETTINGS_TRIGGERED",
  SAVE_SETTINGS_TO_ALL_TRIGGERED: "SAVE_SETTINGS_TO_ALL_TRIGGERED",
};

export const defaultState = () => ({
  order: [],

  visibility: Object.values(TRANSPORT_PAGES).reduce((state, pageName) => {
    state[pageName] = [];
    return state;
  }, {}),
});

export const mutations = {
  [EVENT.SAVE_ORDER_TRIGGERED](state, { newOrder }) {
    state.order = newOrder;
  },

  [EVENT.SAVE_SETTINGS_TRIGGERED](state, { pageName, newSettings }) {
    state.visibility = {
      ...state.visibility,
      [pageName]: newSettings,
    };
  },

  [EVENT.SAVE_SETTINGS_TO_ALL_TRIGGERED](state, newSettings) {
    state.visibility = {
      ...newSettings,
    };
  },
};

export const actions = () => ({
  saveColumnsOrder({ commit, rootState }, order) {
    const { orgRole } = rootState.organization;
    const newOrder = saveColumnsOrder({ order, orgRole });
    commit(EVENT.SAVE_ORDER_TRIGGERED, { newOrder });
  },

  saveColumnsVisibility({ commit, rootState }, columns) {
    const { currentPage: pageName } = rootState;
    const { orgRole } = rootState.organization;
    const newSettings = saveColumnsVisibilityForPage({ orgRole, pageName, columns });

    commit(EVENT.SAVE_SETTINGS_TRIGGERED, {
      pageName,
      newSettings,
    });
  },

  saveColumnsVisibilityToAll({ commit, rootState }, newColumnsSettings) {
    const { orgRole } = rootState.organization;
    const newSettings = saveColumnsVisibilityForAll({ orgRole, newColumnsSettings });
    commit(EVENT.SAVE_SETTINGS_TO_ALL_TRIGGERED, newSettings);
  },
});

export const getters = {
  columnsOrder(state, getters, rootState) {
    const { order } = state;
    const {
      organization: { orgRole },
    } = rootState;

    if (order.length) {
      return order;
    }

    return loadColumnsOrder({ orgRole });
  },

  pageColumnsVisibility(state, getters, rootState) {
    const { visibility } = state;
    const {
      currentPage,
      organization: { orgRole },
    } = rootState;
    let columns;

    if (visibility[currentPage]?.length) {
      columns = visibility[currentPage];
    }
    if (!columns) {
      columns = loadColumnsVisibility({ orgRole, pageName: currentPage });
    }
    return columns;
  },
};

/**
 * Builds a store module for transport table columns settings.
 * @returns {Object} - A Vuex module.
 */
export function buildTransportColumnsSettingsModule() {
  return {
    namespaced: true,

    state: defaultState(),

    mutations,

    actions: actions(),

    getters,
  };
}

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_7059_882" fill="white">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.0471 17.1329C19.6583 15.8391 20 14.393 20 12.8672C20 7.34434 15.5228 2.86719 10 2.86719C4.47715 2.86719 0 7.34434 0 12.8672C0 14.393 0.341743 15.8391 0.952866 17.1329H19.0471Z"
      />
    </mask>
    <path
      d="M19.0471 17.1329V19.1329H20.3144L20.8556 17.9871L19.0471 17.1329ZM0.952866 17.1329L-0.855557 17.9871L-0.314349 19.1329H0.952866V17.1329ZM18 12.8672C18 14.0915 17.7263 15.2464 17.2387 16.2788L20.8556 17.9871C21.5902 16.4318 22 14.6946 22 12.8672H18ZM10 4.86719C14.4183 4.86719 18 8.44891 18 12.8672H22C22 6.23977 16.6274 0.867188 10 0.867188V4.86719ZM2 12.8672C2 8.44891 5.58172 4.86719 10 4.86719V0.867188C3.37258 0.867188 -2 6.23977 -2 12.8672H2ZM2.76129 16.2788C2.27367 15.2464 2 14.0915 2 12.8672H-2C-2 14.6946 -1.59018 16.4318 -0.855557 17.9871L2.76129 16.2788ZM0.952866 19.1329H19.0471V15.1329H0.952866V19.1329Z"
      fill="#A6B5C1"
      mask="url(#path-1-inside-1_7059_882)"
    />
    <mask id="path-3-inside-2_7059_882" fill="white">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5732 17.1328C12.747 16.7649 12.8442 16.3536 12.8442 15.9196C12.8442 14.3488 11.5708 13.0754 10 13.0754C8.42926 13.0754 7.15588 14.3488 7.15588 15.9196C7.15588 16.3536 7.25308 16.7649 7.4269 17.1328H12.5732Z"
      />
    </mask>
    <path
      d="M12.5732 17.1328V19.1328H13.8404L14.3816 17.987L12.5732 17.1328ZM7.4269 17.1328L5.61847 17.987L6.15968 19.1328H7.4269V17.1328ZM10.8442 15.9196C10.8442 16.0521 10.8151 16.1722 10.7648 16.2787L14.3816 17.987C14.6789 17.3575 14.8442 16.6551 14.8442 15.9196H10.8442ZM10 15.0754C10.4663 15.0754 10.8442 15.4534 10.8442 15.9196H14.8442C14.8442 13.2442 12.6754 11.0754 10 11.0754V15.0754ZM9.15588 15.9196C9.15588 15.4534 9.53383 15.0754 10 15.0754V11.0754C7.32469 11.0754 5.15588 13.2442 5.15588 15.9196H9.15588ZM9.23532 16.2787C9.18501 16.1722 9.15588 16.0521 9.15588 15.9196H5.15588C5.15588 16.6551 5.32116 17.3575 5.61847 17.987L9.23532 16.2787ZM7.4269 19.1328H12.5732V15.1328H7.4269V19.1328Z"
      fill="#A6B5C1"
      mask="url(#path-3-inside-2_7059_882)"
    />
    <path
      d="M11.0416 13.3334L13.6458 8.02087"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <ellipse cx="9.99996" cy="6.56246" rx="0.833333" ry="0.833333" fill="currentColor" />
    <circle cx="5.83333" cy="8.51558" r="0.833333" fill="currentColor" />
    <ellipse cx="3.88021" cy="12.474" rx="0.833333" ry="0.833333" fill="currentColor" />
    <circle cx="16.12" cy="12.474" r="0.833333" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  props: {
    size: { type: String, value: "20" },
  },
};
</script>

import { buildTransportsModule } from "@/features/transports/store";
import { getLocationArrivalTransports } from "@/features/locationArrivals/api";
import { SORT_BY, SORT_BY_DIRECTION } from "@/features/transports/api";

export const EVENT = {
  LOCATION_ARRIVALS_REQUESTED: "LOCATION_ARRIVALS_REQUESTED",
};

export const defaultState = (state) => ({
  ...state,
  locationArrivalId: "",
  sorting: {
    sortBy: SORT_BY.ETA_STATUS,
    sortByDirection: SORT_BY_DIRECTION.ASCENDING,
  },
  token: "",
});

export const mutations = {
  [EVENT.LOCATION_ARRIVALS_REQUESTED](state, { locationArrivalId, token }) {
    state.locationArrivalId = locationArrivalId;
    state.token = token;
  },
};

export const actions = () => ({
  async getLocationArrivals({ commit, dispatch }, { locationArrivalId, token }) {
    commit(EVENT.LOCATION_ARRIVALS_REQUESTED, { locationArrivalId, token });
    await dispatch("fetch");
  },
});

/**
 * Builds a store module for the transport arrivals at a location.
 * @returns {Object} - A Vuex module.
 */
export function buildLocationArrivalsModule() {
  const transportModule = buildTransportsModule({
    requestTransports: function (queryParameters, state) {
      queryParameters.token = state.token;
      return getLocationArrivalTransports(state.locationArrivalId, queryParameters);
    },
    extendState: defaultState,
  });

  transportModule.mutations = {
    ...transportModule.mutations,
    ...mutations,
  };

  transportModule.actions = {
    ...transportModule.actions,
    ...actions(),
  };

  return transportModule;
}

import { getOrganization } from "@/features/organization/api";

export const EVENT = {
  ORGANIZATION_REQUEST_FAILED: "ORGANIZATION_REQUEST_FAILED",
  ORGANIZATION_REQUEST_SUCCEEDED: "ORGANIZATION_REQUEST_SUCCEEDED",
};

export const defaultState = () => ({
  error: null,
  orgId: "",
  orgRole: "",
});

export const mutations = {
  [EVENT.ORGANIZATION_REQUEST_FAILED](state, error) {
    state.error = error;
  },

  [EVENT.ORGANIZATION_REQUEST_SUCCEEDED](state, data) {
    state.error = null;
    state.orgId = data.id;
    state.orgRole = data.organizationRole;
  },
};

export const actions = ({ getOrganization }) => ({
  async getOrganization({ commit }) {
    try {
      const data = await getOrganization();
      commit(EVENT.ORGANIZATION_REQUEST_SUCCEEDED, data);
    } catch (error) {
      commit(EVENT.ORGANIZATION_REQUEST_FAILED, error);
      return Promise.reject(error);
    }
  },
});

/**
 * Builds a store module for the user organization.
 * @returns {Object} - A Vuex module.
 */
export function buildOrganizationModule() {
  return {
    namespaced: true,

    state: defaultState(),

    mutations,

    actions: actions({ getOrganization }),
  };
}

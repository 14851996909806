import createAuth0Client from "@auth0/auth0-spa-js";
import jwtDecode from "jwt-decode";
import { PAGES } from "@/router/pageNames";
import Logger from "@/core/logger";
const logger = Logger.getInstance();

let auth0Client = null;

export async function authGuard({ to, next, store }) {
  if (to.meta?.unauthenticated) {
    next();
    return;
  }

  if (!store.getters["auth/isAuthenticated"]) {
    const client = await getAuthClient();
    client.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    return;
  }

  if (!store.state.organization.orgRole) {
    try {
      await store.dispatch("organization/getOrganization");
      next();
    } catch (err) {
      logger.error(err);
      if (err.response && err.response.status >= 500) {
        next({ name: PAGES.SERVER_ERROR });
      } else {
        next({ name: PAGES.NO_ORGANIZATION });
      }
    }

    return;
  }

  next();
}

export function isAuth0Enabled() {
  return process.env.VUE_APP_AUTH0_ENABLED;
}

export async function getAuthClient() {
  if (auth0Client) {
    return auth0Client;
  }
  const config = getAuthConfig();

  auth0Client = await createAuth0Client({
    domain: config.domain,
    client_id: config.clientId,
    audience: config.audience,
    redirect_uri: window.location.origin + "/callback",
  });

  return auth0Client;
}

let inProgressPromise;

export async function getAccessToken() {
  // Required for auth0 client to work.
  // If they don't exist don't try to create a client.
  if (!window || !window.crypto) {
    return null;
  }
  // Calling getTokenSilently multiple times cause errors
  // Using inProgressPromise to tackle such errors
  // https://github.com/auth0/auth0-spa-js/issues/177
  if (inProgressPromise) {
    return await inProgressPromise;
  }

  inProgressPromise = new Promise((resolve, reject) => {
    (async () => {
      try {
        const client = await getAuthClient();
        const token = await client.getTokenSilently();
        inProgressPromise = null;
        resolve(token);
      } catch (err) {
        reject(err);
      }
    })();
  });

  return await inProgressPromise;
}

export function isTokenExpired(accessToken) {
  try {
    const { exp } = jwtDecode(accessToken);
    const nowInSeconds = Date.now() / 1000;
    return nowInSeconds >= exp;
  } catch (error) {
    logger.error(error);
    return true;
  }
}

function getAuthConfig() {
  return {
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  };
}

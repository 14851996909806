import { FILTER_PARAMETERS } from "@/features/transports/api";
import i18n from "@/i18n";

export const additionalFilterConfigDefault = () => ({
  [FILTER_PARAMETERS.PHASE]: {
    options: [
      { text: i18n.t("transports-table.filter.phase.planned"), value: "PLANNED" },
      { text: i18n.t("transports-table.filter.phase.incoming"), value: "INCOMING" },
      { text: i18n.t("transports-table.filter.phase.presumably-incoming"), value: "PRESUMABLY_INCOMING" },
      { text: i18n.t("transports-table.filter.phase.ongoing"), value: "ONGOING" },
      { text: i18n.t("transports-table.filter.phase.presumably-ongoing"), value: "PRESUMABLY_ONGOING" },
      { text: i18n.t("transports-table.filter.phase.cancelled"), value: "CANCELLED" },
      { text: i18n.t("transports-table.filter.phase.completed"), value: "COMPLETED" },
    ],
  },
  [FILTER_PARAMETERS.GPS]: {
    options: [
      { text: i18n.t("transports-table.filter.gps.low"), value: "low" },
      { text: i18n.t("transports-table.filter.gps.lost"), value: "lost" },
    ],
  },
  [FILTER_PARAMETERS.ETA_STATUS]: {
    options: [
      { text: i18n.t("transports-table.filter.status.delayed"), value: "DELAYED" },
      { text: i18n.t("transports-table.filter.status.early"), value: "EARLY" },
      { text: i18n.t("transports-table.filter.status.on-time"), value: "ON_TIME" },
    ],
  },
});

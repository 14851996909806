<template>
  <i v-html="svg" :style="style"></i>
</template>

<script>
import feather from "feather-icons";
import { getCustomCSSProperty } from "@/core/browser";

export default {
  name: "Icon",

  props: {
    color: { type: String, default: "" },
    lineHeight: { type: String, default: "1.25" },
    size: { type: String, default: "24" },
    strokeWidth: { type: String, default: "2" },
    type: { type: String, required: true },
    fill: { type: String, default: "none" },
  },

  computed: {
    style() {
      const color = getCustomCSSProperty(this.color);
      return { color, lineHeight: this.lineHeight };
    },

    svg() {
      return feather.icons[this.type].toSvg({
        height: this.size,
        "stroke-width": this.strokeWidth,
        width: this.size,
        fill: this.fill,
      });
    },
  },
};
</script>

<style scoped>
i {
  line-height: 1.25;
}
</style>

<template>
  <header class="d-flex justify-content-between px-3 py-2 border-bottom">
    <div class="d-flex align-items-center">
      <h2 class="mb-0 font-size-lg text-dark font-weight-bold mr-2">{{ $t("notifications-list.list-title") }}</h2>

      <b-button class="mark-as-read" variant="secondary" size="sm" @click="$emit('mark-all-click')">{{
        $t("notifications-list.mark-all-as-read")
      }}</b-button>
    </div>

    <router-link :to="{ name: 'NotificationsSettings' }" @click.native="$emit('settings-click')">
      <b-button variant="secondary" size="sm"><Icon type="settings" /></b-button>
    </router-link>
  </header>
</template>

<script>
import Icon from "@/components/Icons/Icon";

export default {
  name: "ListHeader",

  components: { Icon },
};
</script>

<style scoped>
.mark-as-read {
  margin-top: 0.125rem;
}
</style>

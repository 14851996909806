import { AGREEMENT_STATUS } from "@/core/agreement";
import { getAgreementRequests } from "@/features/agreements/api";

export const EVENT = {
  REQUEST_AGREEMENT_REQUESTS_FAILED: "REQUEST_AGREEMENT_REQUESTS_FAILED",
  REQUEST_AGREEMENT_REQUESTS_SUCCEEDED: "REQUEST_AGREEMENT_REQUESTS_SUCCEEDED",
};

export const defaultState = () => ({
  agreementRequests: [],
  fetchingAgreementRequestsFailedError: null,
  fetchingAgreementRequestsSucceeded: false,
});

export const mutations = {
  [EVENT.REQUEST_AGREEMENT_REQUESTS_SUCCEEDED](state, agreementRequests) {
    state.agreementRequests = agreementRequests;
    state.fetchingAgreementRequestsFailedError = null;
    state.fetchingAgreementRequestsSucceeded = true;
  },

  [EVENT.REQUEST_AGREEMENT_REQUESTS_FAILED](state, error) {
    state.fetchingAgreementRequestsFailedError = error;
    state.fetchingAgreementRequestsSucceeded = false;
  },
};

export const actions = ({ getAgreementRequests }) => ({
  async getAgreements({ commit }) {
    try {
      const agreementRequests = await getAgreementRequests();
      commit(EVENT.REQUEST_AGREEMENT_REQUESTS_SUCCEEDED, agreementRequests);
    } catch (error) {
      commit(EVENT.REQUEST_AGREEMENT_REQUESTS_FAILED, error);
    }
  },
});

export const getters = {
  pendingAgreementRequests(state) {
    return state.agreementRequests.filter((request) => request.status === AGREEMENT_STATUS.PENDING);
  },
  acceptedAgreements(state) {
    return state.agreementRequests
      .filter((agreement) => agreement.status === AGREEMENT_STATUS.ACCEPTED)
      .sort((a, b) => a.partner.name.localeCompare(b.partner.name));
  },
};

/**
 * Builds a store module for storing agreements.
 * @returns {Object} - A Vuex module.
 */
export function buildAgreementsModule() {
  return {
    namespaced: true,

    state: defaultState(),

    mutations,

    actions: actions({ getAgreementRequests }),

    getters,
  };
}

<template>
  <ErrorToast
    :id="toastId"
    :error-message="$t('unhandled-error-messager.message')"
    :title="$t('unhandled-error-messager.title')"
  />
</template>

<script>
import ErrorToast from "@/components/Toasts/ErrorToast";
const noop = function () {};

export default {
  name: "UnhandledErrorMessager",

  components: {
    ErrorToast,
  },

  data() {
    return {
      toastId: "error-messager",
    };
  },

  mounted() {
    this.registerHandlers();
  },

  methods: {
    registerHandlers() {
      const { showErrorMessage } = this;
      const oldErrorHandler = window.onerror || noop;

      window.onerror = function (message, source, lineno, colno, error) {
        if (error) {
          showErrorMessage();
        }
        oldErrorHandler(message, source, lineno, colno, error);
        return true;
      };

      const oldPromiseRejectionHandler = window.onunhandledrejection || noop;

      window.onunhandledrejection = function (promiseRejectionEvent) {
        if (promiseRejectionEvent) {
          showErrorMessage();
        }
        oldPromiseRejectionHandler(promiseRejectionEvent.reason);
        return true;
      };
    },

    showErrorMessage() {
      this.$bvToast.show(this.toastId);
    },
  },
};
</script>

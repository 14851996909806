/**
 * Module for manipulating dates and time.
 **/
import addMins from "date-fns/addMinutes";
import de from "date-fns/locale/de";
import diffInDays from "date-fns/differenceInDays";
import diffInHours from "date-fns/differenceInHours";
import diffInMinutes from "date-fns/differenceInMinutes";
import en from "date-fns/locale/en-GB";
import format from "date-fns-tz/format";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import isEqual from "date-fns/isEqual";
import isItAfter from "date-fns/isAfter";
import isItBefore from "date-fns/isBefore";
import isItSameDay from "date-fns/isSameDay";
import parseISO from "date-fns/parseISO";
import subMins from "date-fns/subMinutes";
import subHours from "date-fns/subHours";
import toZonedDate from "date-fns-tz/toDate";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import zonedTimeToUtc from "date-fns-tz/zonedTimeToUtc";

const LOCALES = {
  de,
  en,
};

/**
 * Adds minutes to a date and returns a new Date object.
 * @param {Date | string} date - Either a Date object or string representation of a date.
 * @param {Number} minutes - The amount of minutes to add.
 * @returns {Date}
 */
export function addMinutes(date, minutes) {
  return addMins(getDateObject(date), minutes);
}

/**
 * Returns if the two dates are on the same.
 * @param {Date | string} dateA - Either a Date object or string representation of a date.
 * @param {Date | string} dateB - Either a Date object or string representation of a date.
 * @returns {boolean}
 */
export function areEqual(dateA, dateB) {
  return isEqual(getDateObject(dateA), getDateObject(dateB));
}

/**
 * Returns if the month or day value of a date string are valid.
 * @param {string} dateString - A string in the format of yyyy-MM-DD.
 * @returns {boolean}
 */
export function areMonthAndDayValid(dateString) {
  // eslint-disable-next-line no-unused-vars
  const [year, month, day] = dateString.split("-");
  const parsedMonth = parseInt(month);
  const parsedDay = parseInt(day);

  if (isNaN(parsedMonth) || isNaN(parsedDay)) {
    throw Error("dateString is not in the expected format.");
  }

  if (
    parsedMonth < 1 ||
    parsedMonth > 12 ||
    parsedDay < 1 ||
    parsedDay > 31 ||
    (parsedMonth === 2 && parsedDay > 29) ||
    (parsedMonth === 4 && parsedDay > 30) ||
    (parsedMonth === 6 && parsedDay > 30) ||
    (parsedMonth === 9 && parsedDay > 30) ||
    (parsedMonth === 11 && parsedDay > 30)
  ) {
    return false;
  }

  return true;
}

export function convertDateToTimeZone(date, timeZone) {
  return toZonedDate(date, { timeZone });
}

export function convertTimeZoneToUtc(date, timeZone) {
  return zonedTimeToUtc(date, timeZone);
}

export function convertUtcToTimeZone(date, timeZone) {
  return utcToZonedTime(date, timeZone);
}

/**
 * Returns the difference between two dates in a formatted string of hours and minutes.
 * @param {Date | string} fromDate - The from date to compare with.
 * @param {Date | string} toDate - The to date to compare with.
 * @returns {string}
 */
export function differenceInDaysHoursMinutes(fromDate, toDate) {
  const diffInDays = differenceInDays(toDate, fromDate);
  const diffInHours = differenceInHours(toDate, fromDate);
  const diffInMins = differenceInMinutes(toDate, fromDate);

  const days = diffInDays;
  const hours = diffInHours - diffInDays * 24;
  const minutes = diffInMins - diffInHours * 60;

  if (days) {
    return `${days}d ${hours}h ${minutes}min`;
  }

  if (hours) {
    return `${hours}h ${minutes}min`;
  }

  return `${minutes}min`;
}

/**
 * Returns the difference between two dates in full days.
 * @param {Date | string} laterDate - The later date to compare with.
 * @param {Date | string} earlierDate - The earlier date to compare with.
 * @returns {number}
 */
export function differenceInDays(laterDate, earlierDate) {
  return diffInDays(getDateObject(laterDate), getDateObject(earlierDate));
}

/**
 * Returns the difference between two dates in hours.
 * @param {Date | string} laterDate - The later date to compare with.
 * @param {Date | string} earlierDate - The earlier date to compare with.
 * @returns {number}
 */
export function differenceInHours(laterDate, earlierDate) {
  return diffInHours(getDateObject(laterDate), getDateObject(earlierDate));
}

/**
 * Returns the difference between two dates in minutes.
 * @param {Date | string} laterDate - The later date to compare with.
 * @param {Date | string} earlierDate - The earlier date to compare with.
 * @returns {number}
 */
export function differenceInMinutes(laterDate, earlierDate) {
  return diffInMinutes(getDateObject(laterDate), getDateObject(earlierDate));
}

/**
 * Returns the difference between a date and now in words.
 * @param {Date | string } date - The date to compare to now.
 * @returns {string}
 */
export function distanceToNowInWords(date) {
  if (!date) {
    return "";
  }

  return formatDistanceToNowStrict(getDateObject(date));
}

/**
 * Formats a date into a string representation.
 * @param {Date | string} date - Either a Date object or string representation of a date.
 * @param { string } formatStr - The format to use.
 * @param { string } locale - The locale to use.
 * @param { string } timeZone - The time zone to use. Defaults to the browser time zone if not provided.
 * @returns {string}
 */
export function formatDate(date, formatStr, locale, timeZone = undefined) {
  if (!locale) {
    throw new Error("No locale provided to core/formatDate");
  }

  const tz = timeZone || getBrowserTimeZone();

  return format(getDateObject(date), formatStr, { locale: LOCALES[locale], timeZone: tz });
}

/**
 * Formats a range of a from and to date into a string.
 * @param { Object } range - Object with from and to properties.
 * @param { string } locale - The locale to use.
 * @param { string } timeZone - The time zone to use.
 * @returns {string}
 */
export function formatDateRange(range, locale, timeZone = undefined) {
  if (!range) {
    return "";
  }

  const { from, to } = range;

  if (areEqual(from, to)) {
    return formatLongDate(from, locale, timeZone);
  }

  const fromStr = formatLongDate(from, locale, timeZone);
  const toStr = isSameDay(from, to) ? formatDateTime(to, locale, timeZone) : formatLongDate(to, locale, timeZone);

  return `${fromStr} - ${toStr}`;
}

/**
 * Formats a date into the application format for displaying time.
 * @param {Date | string} date - Either a Date object or string representation of a date.
 * @param { string } locale - The locale to use.
 * @param { string } timeZone - The time zone to use.
 * @returns {string}
 */
export function formatDateTime(date, locale, timeZone = undefined) {
  return formatDate(getDateObject(date), "p", locale, timeZone);
}

/**
 * Formats a date into the application format for long date strings.
 * @param {Date | string} date - Either a Date object or string representation of a date.
 * @param { string } locale - The locale to use.
 * @param { string } timeZone - The time zone to use.
 * @returns {string}
 */
export function formatLongDate(date, locale, timeZone = undefined) {
  return formatDate(getDateObject(date), "eee dd MMM p", locale, timeZone);
}

export function getDateMask(locale) {
  switch (locale) {
    default:
      return deDateMask;
  }
}

/**
 * Returns the date of a Date object in ISO format.
 * Example "2020-12-24".
 * @param {Date} date - The Date object to format.
 * @returns {string}
 */
export function getISODateString(date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`;
}

/**
 * Returns the time of a Date object in ISO format.
 * Example "12:00:00".
 * @param {Date} date - The Date object to format.
 * @returns {string}
 */
export function getISOTimeString(date) {
  return date.toTimeString().slice(0, 8);
}

/**
 * Returns the time zone of the browser.
 * @returns {string}
 */
export function getBrowserTimeZone() {
  return new Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getYMDDate(locale, dateString) {
  switch (locale) {
    default:
      return deDateToYMD(dateString);
  }
}

/**
 * Returns if the first date is after the second one.
 * @param {Date | string} dateThatShouldBeAfter - Either a Date object or string representation of a date.
 * @param {Date | string} dateToCompare - Either a Date object or string representation of a date.
 * @returns {boolean}
 */
export function isAfter(dateThatShouldBeAfter, dateToCompare) {
  return isItAfter(getDateObject(dateThatShouldBeAfter), getDateObject(dateToCompare));
}

/**
 * Returns if the first date is before the second one.
 * @param {Date | string} dateThatShouldBeBefore - Either a Date object or string representation of a date.
 * @param {Date | string} dateToCompare - Either a Date object or string representation of a date.
 * @returns {boolean}
 */
export function isBefore(dateThatShouldBeBefore, dateToCompare) {
  return isItBefore(getDateObject(dateThatShouldBeBefore), getDateObject(dateToCompare));
}

/**
 * Returns if the two dates are on the same day.
 * @param {Date | string} dateA - Either a Date object or string representation of a date.
 * @param {Date | string} dateB - Either a Date object or string representation of a date.
 * @returns {boolean}
 */
export function isSameDay(dateA, dateB) {
  return isItSameDay(getDateObject(dateA), getDateObject(dateB));
}

/**
 * Subtracts minutes from a date and returns a new Date object.
 * @param {Date | string} date - Either a Date object or string representation of a date.
 * @param {Number} minutes - The amount of minutes to add.
 * @returns {Date}
 */
export function subtractMinutes(date, minutes) {
  return subMins(getDateObject(date), minutes);
}

/**
 * Subtracts hours from a date and returns a new Date object.
 * @param {Date | string} date - Either a Date object or string representation of a date.
 * @param {Number} hours - The amount of hours to add.
 * @returns {Date}
 */
export function subtractHours(date, hours) {
  return subHours(getDateObject(date), hours);
}

function deDateMask(value) {
  const output = [];

  for (let i = 0; i < value.length; i++) {
    if (i !== 0 && i < 6 && i % 2 === 0) {
      output.push(".");
    }

    output.push(value[i]);
  }

  return output.join("");
}

function deDateToYMD(dateString) {
  if (dateString.length < 8) {
    return "";
  }

  const [day, month, year] = dateString.split(".");
  return [`${year.length === 4 ? year : "20" + year}`, month, day].join("-");
}

function getDateObject(date) {
  if (typeof date === "string") {
    return parseISO(date);
  }

  return date;
}

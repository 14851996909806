import { getHttpClient } from "@/core/api";

export async function getWaypointEvents({ transportId, waypointId }) {
  const response = await getHttpClient().get(`v2/transports/${transportId}/waypoints/${waypointId}/events`);
  return response.data;
}

export async function putWaypointCompletedStatus(transportId, waypointId, timestamp) {
  const response = await getHttpClient().put(`v2/transports/${transportId}/waypoints/${waypointId}/status`, {
    status: "COMPLETED",
    timestamp: timestamp,
  });
  return response.data;
}

export async function getTransportLog(transportId) {
  const response = await getHttpClient().get(`v2/transports/${transportId}/log`);
  return response.data;
}

export async function getTransportOnsiteProcessingTimes(transportId) {
  const response = await getHttpClient().get(`v2/transports/${transportId}/onsite-processing-times`);
  return response.data;
}

export async function getGpsHealthCheck(transportId) {
  const response = await getHttpClient().get(`v2/transports/${transportId}/gps-health-check`);
  return response.data;
}

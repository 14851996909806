import { TRANSPORT_PAGES } from "@/router/pageNames";

export function migrateColumns({ pageName, columnSettings }) {
  const { columns, version } = columnSettings;
  // We use parseInt here because versions used to be saved as strings.

  // Change in version 10:
  // - gpsSignalIndicator column was added to Planned Transports.
  if (parseInt(version) < 10) {
    if (pageName === TRANSPORT_PAGES.PLANNED_TRANSPORTS) {
      columns.splice(7, 0, { key: "gpsSignalIndicator", enabled: true });
    }
  }

  // Change in version 11:
  // - from column was removed from Location Arrivals.
  if (parseInt(version) < 11) {
    if (pageName === TRANSPORT_PAGES.LOCATION_ARRIVAL_TRANSPORTS) {
      const index = columns.findIndex((column) => column.key === "from");
      if (index > -1) {
        columns.splice(index, 1);
      }
    }
  }

  // Change in version 12:
  // - actionRequired column was added.
  // - display of transportStatus column was changed for some pages.
  if (parseInt(version) < 12) {
    if ([TRANSPORT_PAGES.GPS_MISSING_TRANSPORTS, TRANSPORT_PAGES.TRACKING_ID_MISSING_TRANSPORTS].includes(pageName)) {
      const index = columns.findIndex((column) => column.key === "transportStatus");
      if (index > -1) {
        columns.splice(index, 1);
      }
    }

    if (
      [
        TRANSPORT_PAGES.ARRIVED_TRANSPORTS,
        TRANSPORT_PAGES.AREA_ARRIVED_TRANSPORTS,
        TRANSPORT_PAGES.MANUALLY_COMPLETED_TRANSPORTS,
        TRANSPORT_PAGES.OVERDUE_TRANSPORTS,
      ].includes(pageName)
    ) {
      columns.splice(0, 0, { key: "transportStatus", enabled: true });
    }

    if (
      [
        TRANSPORT_PAGES.COMPLETED_TRANSPORTS,
        TRANSPORT_PAGES.AREA_ARRIVED_TRANSPORTS,
        TRANSPORT_PAGES.ARRIVED_TRANSPORTS,
        TRANSPORT_PAGES.MANUALLY_COMPLETED_TRANSPORTS,
        TRANSPORT_PAGES.OVERDUE_TRANSPORTS,
        TRANSPORT_PAGES.LOCATION_ARRIVAL_TRANSPORTS,
      ].includes(pageName)
    ) {
      columns.splice(1, 0, { key: "actionRequired", enabled: false });
    }

    if (
      [
        TRANSPORT_PAGES.ACTION_REQUIRED_TRANSPORTS,
        TRANSPORT_PAGES.GPS_LOW_TRANSPORTS,
        TRANSPORT_PAGES.GPS_LOST_TRANSPORTS,
        TRANSPORT_PAGES.GPS_MISSING_TRANSPORTS,
        TRANSPORT_PAGES.INCOMING_TRANSPORTS,
        TRANSPORT_PAGES.ONGOING_TRANSPORTS,
        TRANSPORT_PAGES.SEARCH_RESULTS,
        TRANSPORT_PAGES.TRACKING_ID_MISSING_TRANSPORTS,
      ].includes(pageName)
    ) {
      const index = columns.findIndex((column) => column.key === "transportStatus");
      const actionRequiredIndex = index + 1;
      columns.splice(actionRequiredIndex, 0, { key: "actionRequired", enabled: true });
    }
  }

  // Change in version 13:
  // - ata columns were removed from incoming and ongoing transports
  //   as they got new column settings.
  if (parseInt(version) < 13) {
    if ([TRANSPORT_PAGES.INCOMING_TRANSPORTS, TRANSPORT_PAGES.ONGOING_TRANSPORTS].includes(pageName)) {
      const index = columns.findIndex((column) => column.key === "ata");
      if (index > -1) {
        columns.splice(index, 1);
      }
    }
  }

  // Change in version 14:
  // - The key was changed to support saving settings based on orgRole. We remove the old settings.
  if (parseInt(version) < 14) {
    localStorage.removeItem(`settings.transportTable.${pageName}.columns`);
  }

  // Change in version 15:
  // - Add cancelledAt column to search results
  if (parseInt(version) < 15) {
    if (pageName === TRANSPORT_PAGES.SEARCH_RESULTS) {
      columns.splice(3, 0, { key: "cancelledAt", enabled: true, sortable: true });
    }
  }

  // Change in version 16:
  // - Add Transport Status to GPS Missing and Tracking Id Missing pages.
  if (parseInt(version) < 16) {
    if (
      pageName === TRANSPORT_PAGES.GPS_MISSING_TRANSPORTS ||
      pageName === TRANSPORT_PAGES.TRACKING_ID_MISSING_TRANSPORTS
    ) {
      columns.splice(0, 0, { key: "transportStatus", enabled: true });
    }

    // Change in version 17:
    // - Remove transportStatus from Completed Overdue transports.
    if (parseInt(version) < 17) {
      if (pageName === TRANSPORT_PAGES.OVERDUE_TRANSPORTS) {
        const index = columns.findIndex((column) => column.key === "transportStatus");
        if (index > -1) {
          columns.splice(index, 1);
        }
      }
    }
  }

  // Change in version 18:
  // - next waypoint columns.
  if (parseInt(version) < 18) {
    //scheduledDate, nextScheduled
    if (
      [
        TRANSPORT_PAGES.ACTION_REQUIRED_TRANSPORTS,
        TRANSPORT_PAGES.TRACKING_ID_MISSING_TRANSPORTS,
        TRANSPORT_PAGES.GPS_MISSING_TRANSPORTS,
        TRANSPORT_PAGES.GPS_LOW_TRANSPORTS,
        TRANSPORT_PAGES.GPS_LOST_TRANSPORTS,
        TRANSPORT_PAGES.WAYPOINT_CONFIRMATION_TRANSPORTS,
        TRANSPORT_PAGES.PLANNED_TRANSPORTS,
        TRANSPORT_PAGES.INCOMING_TRANSPORTS,
        TRANSPORT_PAGES.ONGOING_TRANSPORTS,
        TRANSPORT_PAGES.SEARCH_RESULTS,
      ].includes(pageName)
    ) {
      const ptaIndex = columns.findIndex((column) => column.key === "scheduledDate");
      if (ptaIndex > -1) {
        //modify pta column
        columns.splice(ptaIndex, 1, { key: "scheduledDate", enabled: false, sortable: true });
        //add nextScheduled column
        columns.splice(ptaIndex + 1, 0, { key: "nextScheduled", enabled: true });
      }
    }
    //eta, nextEta
    if (
      [
        TRANSPORT_PAGES.ACTION_REQUIRED_TRANSPORTS,
        TRANSPORT_PAGES.GPS_LOW_TRANSPORTS,
        TRANSPORT_PAGES.GPS_LOST_TRANSPORTS,
        TRANSPORT_PAGES.WAYPOINT_CONFIRMATION_TRANSPORTS,
        TRANSPORT_PAGES.INCOMING_TRANSPORTS,
        TRANSPORT_PAGES.ONGOING_TRANSPORTS,
        TRANSPORT_PAGES.SEARCH_RESULTS,
      ].includes(pageName)
    ) {
      const etaIndex = columns.findIndex((column) => column.key === "eta");
      if (etaIndex > -1) {
        //modify eta column
        columns.splice(etaIndex, 1, { key: "eta", enabled: false, sortable: true });
        //add nextEta column
        columns.splice(etaIndex + 1, 0, { key: "nextEta", enabled: true });
      }
    }
    //nextAddress
    if (
      [
        TRANSPORT_PAGES.ACTION_REQUIRED_TRANSPORTS,
        TRANSPORT_PAGES.TRACKING_ID_MISSING_TRANSPORTS,
        TRANSPORT_PAGES.GPS_MISSING_TRANSPORTS,
        TRANSPORT_PAGES.GPS_LOW_TRANSPORTS,
        TRANSPORT_PAGES.GPS_LOST_TRANSPORTS,
        TRANSPORT_PAGES.WAYPOINT_CONFIRMATION_TRANSPORTS,
        TRANSPORT_PAGES.PLANNED_TRANSPORTS,
        TRANSPORT_PAGES.INCOMING_TRANSPORTS,
        TRANSPORT_PAGES.ONGOING_TRANSPORTS,
        TRANSPORT_PAGES.SEARCH_RESULTS,
      ].includes(pageName)
    ) {
      const fromIndex = columns.findIndex((column) => column.key === "from");
      if (fromIndex > -1) {
        if (
          [
            TRANSPORT_PAGES.PLANNED_TRANSPORTS,
            TRANSPORT_PAGES.INCOMING_TRANSPORTS,
            TRANSPORT_PAGES.ONGOING_TRANSPORTS,
          ].includes(pageName)
        ) {
          //modify from column
          columns.splice(fromIndex, 1, { key: "from", enabled: false });
        }
        //add nextAddress column
        columns.splice(fromIndex + 1, 0, { key: "nextAddress", enabled: true });
      }
    }
  }

  // Change in version 19:
  // - remove ata from presumable transport tables
  if (parseInt(version) < 19) {
    if (
      [TRANSPORT_PAGES.PRESUMABLY_INCOMING_TRANSPORTS, TRANSPORT_PAGES.PRESUMABLY_ONGOING_TRANSPORTS].includes(pageName)
    ) {
      const index = columns.findIndex((column) => column.key === "ata");
      if (index > -1) {
        columns.splice(index, 1);
      }
    }
  }

  // Change in version 20:
  // - rename column `transportStatus` to `punctuality`
  if (parseInt(version) < 20) {
    columns.forEach((column) => {
      if (column.key === "transportStatus") {
        column.key = "punctuality";
      }
    });
  }

  // Change in version 21:
  // - add column action-buttons to search results
  if (parseInt(version) < 21) {
    if ([TRANSPORT_PAGES.SEARCH_RESULTS].includes(pageName)) {
      columns.push({ key: "actions", enabled: true });
    }
  }

  // Change in version 22:
  // - add column phase to search results
  if (parseInt(version) < 22) {
    if (
      [
        TRANSPORT_PAGES.ACTION_REQUIRED_TRANSPORTS,
        TRANSPORT_PAGES.TRACKING_ID_MISSING_TRANSPORTS,
        TRANSPORT_PAGES.GPS_MISSING_TRANSPORTS,
        TRANSPORT_PAGES.GPS_LOST_TRANSPORTS,
        TRANSPORT_PAGES.GPS_LOW_TRANSPORTS,
        TRANSPORT_PAGES.WAYPOINT_CONFIRMATION_TRANSPORTS,
        TRANSPORT_PAGES.SEARCH_RESULTS,
        TRANSPORT_PAGES.WATCHLIST,
      ].includes(pageName)
    ) {
      columns.splice(0, 0, { key: "phase", enabled: true });
    }
  }

  // Change in version 23:
  // - sortable next PTA & ETA
  if (parseInt(version) < 23) {
    if (
      [
        TRANSPORT_PAGES.ACTION_REQUIRED_TRANSPORTS,
        TRANSPORT_PAGES.TRACKING_ID_MISSING_TRANSPORTS,
        TRANSPORT_PAGES.GPS_MISSING_TRANSPORTS,
        TRANSPORT_PAGES.GPS_LOW_TRANSPORTS,
        TRANSPORT_PAGES.GPS_LOST_TRANSPORTS,
        TRANSPORT_PAGES.WAYPOINT_CONFIRMATION_TRANSPORTS,
        TRANSPORT_PAGES.PLANNED_TRANSPORTS,
        TRANSPORT_PAGES.INCOMING_TRANSPORTS,
        TRANSPORT_PAGES.ONGOING_TRANSPORTS,
        TRANSPORT_PAGES.PRESUMABLY_INCOMING_TRANSPORTS,
        TRANSPORT_PAGES.PRESUMABLY_ONGOING_TRANSPORTS,
        TRANSPORT_PAGES.SEARCH_RESULTS,
        TRANSPORT_PAGES.WATCHLIST,
      ].includes(pageName)
    ) {
      const nextPTAIndex = columns.findIndex((column) => column.key === "nextScheduled");
      if (nextPTAIndex > -1) {
        columns.splice(nextPTAIndex, 1, { key: "nextScheduled", enabled: true, sortable: true });
      }
      const nextETAIndex = columns.findIndex((column) => column.key === "nextEta");
      if (nextETAIndex > -1) {
        columns.splice(nextETAIndex, 1, { key: "nextEta", enabled: true, sortable: true });
      }
    }
  }

  // Change in version 24:
  // - remove punctuality column on presumably incoming and presumably ongoing pages
  if (parseInt(version) < 24) {
    if (
      [TRANSPORT_PAGES.PRESUMABLY_INCOMING_TRANSPORTS, TRANSPORT_PAGES.PRESUMABLY_ONGOING_TRANSPORTS].includes(pageName)
    ) {
      const index = columns.findIndex((column) => column.key === "punctuality");
      if (index > -1) {
        columns.splice(index, 1);
      }
    }
  }

  // Change in version 25:
  // - add column action-buttons to cancelled transports
  // - add column action-buttons to sub sites of completed:
  //   * Arrived
  //   * Area Arrived
  //   * Manually Completed,
  //   * Overdue
  if (parseInt(version) < 25) {
    if (
      [
        TRANSPORT_PAGES.ARRIVED_TRANSPORTS,
        TRANSPORT_PAGES.AREA_ARRIVED_TRANSPORTS,
        TRANSPORT_PAGES.MANUALLY_COMPLETED_TRANSPORTS,
        TRANSPORT_PAGES.OVERDUE_TRANSPORTS,
        TRANSPORT_PAGES.CANCELLED_TRANSPORTS,
      ].includes(pageName)
    ) {
      columns.push({ key: "actions", enabled: true });
    }
  }

  return columnSettings;
}

export function migrateOrder(orderSettings) {
  const { order, version } = orderSettings;

  // Change in version 20:
  // - rename column `transportStatus` to `punctuality`
  if (parseInt(version) < 20) {
    order.forEach((column) => {
      if (column.key === "transportStatus") {
        column.key = "punctuality";
      }
    });
  }

  // Change in version 22:
  // - add column phase to order
  if (parseInt(version) < 22) {
    if (!order.find((column) => column.key === "phase")) {
      order.splice(0, 0, { key: "phase" });
    }
  }

  return orderSettings;
}

<template>
  <b-button variant="link" @click="$emit('click')">
    <div class="position-relative d-flex align-items-center justify-content-center trigger-button">
      <Icon type="bell" />
      <div v-if="hasUnreadNotifications" class="position-absolute bg-danger new-notification-icon"></div>
    </div>
  </b-button>
</template>

<script>
import Icon from "@/components/Icons/Icon";

export default {
  name: "MenuButton",

  components: {
    Icon,
  },

  props: {
    hasUnreadNotifications: Boolean,
  },
};
</script>

<style scoped>
.new-notification-icon {
  height: 0.875rem;
  width: 0.875rem;
  border-radius: 50%;
  right: 0.125rem;
  top: 0;
}

.trigger-button {
  height: 2rem;
  width: 2rem;
}
</style>

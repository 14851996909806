import { FILTER_PARAMETERS, SORT_BY, SORT_BY_DIRECTION } from "@/features/transports/api";
import { filtersToQueryParameters } from "@/core/api";
import { additionalFilterConfigDefault } from "@/features/transports/additionalFilterConfigDefault";
import i18n from "@/i18n";

export const EVENT = {
  PAGE_CHANGED: "PAGE_CHANGED",
  REQUEST_TRANSPORTS_FAILED: "REQUEST_TRANSPORTS_FAILED",
  REQUEST_TRANSPORTS_SUCCEEDED: "REQUEST_TRANSPORTS_SUCCEEDED",
  SHOW_FILTERS_CHANGED: "SHOW_FILTERS_CHANGED",
  SORTING_CHANGED: "SORTING_CHANGED",
  TRANSPORTS_REQUESTED: "TRANSPORTS_REQUESTED",
};

export const defaultState = () => ({
  error: null,
  isRequestInProgress: false,
  page: 0,
  sorting: {
    sortBy: SORT_BY.SCHEDULED,
    sortByDirection: SORT_BY_DIRECTION.ASCENDING,
  },
  additionalFilterConfig: additionalFilterConfigDefault(),
  showFilters: false,
  transports: {},
});

export const mutations = {
  [EVENT.TRANSPORTS_REQUESTED](state) {
    state.isRequestInProgress = true;
  },

  [EVENT.REQUEST_TRANSPORTS_SUCCEEDED](state, response) {
    state.error = null;
    state.transports = response;
    state.isRequestInProgress = false;
  },

  [EVENT.REQUEST_TRANSPORTS_FAILED](state, error) {
    state.isRequestInProgress = false;
    state.error = error;
  },

  [EVENT.SHOW_FILTERS_CHANGED](state, showFilters) {
    state.showFilters = showFilters;
  },

  [EVENT.SORTING_CHANGED](state, sortBy) {
    if (!sortBy) {
      throw new Error("Cannot set a search field of null or undefined");
    }

    if (state.sorting.sortBy === sortBy) {
      state.sorting = {
        ...state.sorting,
        sortByDirection:
          state.sorting.sortByDirection === SORT_BY_DIRECTION.ASCENDING
            ? SORT_BY_DIRECTION.DESCENDING
            : SORT_BY_DIRECTION.ASCENDING,
      };
      return;
    }

    state.sorting = {
      sortBy,
      sortByDirection: SORT_BY_DIRECTION.ASCENDING,
    };
  },

  [EVENT.PAGE_CHANGED](state, page) {
    state.page = page;
  },
};

export const actions = ({ requestTransports }) => ({
  async fetch({ commit, state, rootGetters }) {
    const {
      page,
      sorting: { sortBy, sortByDirection },
    } = state;

    let queryParameters = {
      page,
      sortBy,
      sortByDirection,
    };

    if (rootGetters.transportTableFilters) {
      queryParameters = filtersToQueryParameters(rootGetters.transportTableFilters, queryParameters);
    }

    commit(EVENT.TRANSPORTS_REQUESTED);

    try {
      const response = await requestTransports(queryParameters, state);
      commit(EVENT.REQUEST_TRANSPORTS_SUCCEEDED, response);
    } catch (error) {
      commit(EVENT.REQUEST_TRANSPORTS_FAILED, error);
    }
  },

  hideFilters({ commit }) {
    commit(EVENT.SHOW_FILTERS_CHANGED, false);
  },

  async setSort({ commit, dispatch }, sortBy) {
    commit(EVENT.SORTING_CHANGED, sortBy);
    await dispatch("fetch");
  },

  async setPage({ commit }, page) {
    commit(EVENT.PAGE_CHANGED, page);
  },

  toggleFilters({ commit, state }) {
    commit(EVENT.SHOW_FILTERS_CHANGED, !state.showFilters);
  },

  async updatePage({ commit, dispatch }, page) {
    commit(EVENT.PAGE_CHANGED, page);
    await dispatch("fetch");
  },
});

/**
 * Builds a store module for different kinds of transports.
 * @param {Object} options - Build options.
 * @param {Function} options.requestTransports - A function to get transports from the API.
 * @returns {Object} - A Vuex module.
 */
export function buildTransportsModule({ requestTransports, extendState }) {
  extendState = extendState || ((state) => state);
  return {
    namespaced: true,

    state: () => extendState(defaultState()),

    mutations,

    actions: actions({ requestTransports }),

    getters: {},
  };
}

export function buildCancelledTransportsModule({ requestTransports }) {
  const extendState = (state) => ({
    ...state,
    sorting: {
      sortBy: SORT_BY.CANCELLED_AT,
      sortByDirection: SORT_BY_DIRECTION.DESCENDING,
    },
  });

  return buildTransportsModule({ requestTransports, extendState });
}

export function buildActionsRequiredModule({ requestTransports }) {
  const extendState = (state) => ({
    ...state,
    additionalFilterConfig: {
      ...state.additionalFilterConfig,
      [FILTER_PARAMETERS.PHASE]: {
        options: [
          { text: i18n.t("transports-table.filter.phase.incoming"), value: "INCOMING" },
          { text: i18n.t("transports-table.filter.phase.presumably-incoming"), value: "PRESUMABLY_INCOMING" },
          { text: i18n.t("transports-table.filter.phase.ongoing"), value: "ONGOING" },
          { text: i18n.t("transports-table.filter.phase.presumably-ongoing"), value: "PRESUMABLY_ONGOING" },
        ],
      },
    },
  });

  return buildTransportsModule({ requestTransports, extendState });
}

export function buildCompletedTransportsModule({ requestTransports }) {
  const extendState = (state) => ({
    ...state,
    sorting: {
      sortBy: SORT_BY.ARRIVED,
      sortByDirection: SORT_BY_DIRECTION.DESCENDING,
    },
  });

  return buildTransportsModule({ requestTransports, extendState });
}

import i18n from "@/i18n";
import { FILTER_PARAMETERS } from "@/features/transports/api";
import { formatDateRange } from "@/core/date";

const LOCAL_STORAGE_KEY = "settings.transportTable.filters";

export function loadFilterSettings() {
  const storageItem = localStorage.getItem(LOCAL_STORAGE_KEY);

  return storageItem ? JSON.parse(storageItem) : {};
}

export function saveFilterSettings(filters) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(filters));
}

const filterFormatters = {
  [FILTER_PARAMETERS.FROM]: function ({ filter }) {
    return Object.values(filter)
      .filter((value) => Boolean(value))
      .join(", ");
  },

  [FILTER_PARAMETERS.GPS]: function ({ filter }) {
    return filter.map((value) => i18n.t(`filters.values.${value}`)).join(", ");
  },

  [FILTER_PARAMETERS.TO]: function ({ filter }) {
    return Object.values(filter)
      .filter((value) => Boolean(value))
      .join(", ");
  },

  [FILTER_PARAMETERS.CARRIERS]: function ({ filter, carriersWithTransports }) {
    return carriersWithTransports
      .filter((carrier) => filter.includes(carrier.id))
      .map((carrier) => carrier.name)
      .join(", ");
  },

  [FILTER_PARAMETERS.SHIPPERS]: function ({ filter, shippersWithTransports }) {
    return shippersWithTransports
      .filter((shipper) => filter.includes(shipper.id))
      .map((shipper) => shipper.name)
      .join(", ");
  },

  [FILTER_PARAMETERS.REFERENCE]: ({ filter }) => filter,

  [FILTER_PARAMETERS.ETA_STATUS]: function ({ filter }) {
    const translations = filter.map((value) => {
      return i18n.t(`common.${value.toLowerCase().replace("_", "-")}`);
    });

    return translations.join(", ");
  },

  [FILTER_PARAMETERS.SCHEDULED]: function ({ filter }) {
    const [from, to] = filter;

    return formatDateRange({ from, to }, i18n.locale);
  },

  [FILTER_PARAMETERS.TRACKING_ID]: ({ filter }) => filter,

  [FILTER_PARAMETERS.NEXT_WAYPOINT]: function ({ filter }) {
    return Object.values(filter)
      .filter((value) => Boolean(value))
      .join(", ");
  },

  [FILTER_PARAMETERS.PHASE]: function ({ filter }) {
    const translations = filter.map((value) => {
      return i18n.t(`common.${value.toLowerCase().replace("_", "-")}`);
    });

    return translations.join(", ");
  },
};

export function adaptFilters(transportTableFilters, shippersWithTransports, carriersWithTransports) {
  if (!transportTableFilters) {
    return [];
  }
  const filters = transportTableFilters;
  const filterTypes = Object.keys(filters);
  const typeTranslations = Object.values(FILTER_PARAMETERS).reduce((translations, value) => {
    translations[value] = i18n.t(`filters.labels.${value}`);
    return translations;
  }, {});

  if (!filterTypes.length) {
    return [];
  }

  // Based on the active filter types,
  // we remove any empty filter values
  // and return the necessary info to display.
  return filterTypes
    .filter((type) => (Array.isArray(filters[type]) ? Boolean(filters[type].length) : Boolean(filters[type])))
    .map((type) => {
      const label = typeTranslations[type];
      const filter = filters[type];
      const value = filterFormatters[type]({ filter, shippersWithTransports, carriersWithTransports });

      return {
        label,
        type,
        value,
      };
    });
}

import { getHttpClient } from "@/core/api";

export async function getAgreementRequests() {
  const response = await getHttpClient().get("v2/agreements");
  return response.data;
}

export async function postAcceptAgreementRequest(agreementId) {
  const response = await getHttpClient().post(`v2/agreements/${agreementId}/accept`);
  return response.data;
}

export async function postDeclineAgreementRequest(agreementId) {
  const response = await getHttpClient().post(`v2/agreements/${agreementId}/decline`);
  return response.data;
}

export async function postInvitationId(invitationId) {
  const response = await getHttpClient().post("v2/agreements", { invitationId });
  return response.data;
}

/**
 * Module with JavaScript helpers.
 */

/**
 * Capitalizes the first letter of a string
 * @param {string} str - The string to capitalize.
 * @returns {string}
 */
export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Returns a debounced version of the provided function.
 * @param {function} func - The function to debounce.
 * @param {number} delayInMs - The duration of the debounce.
 * @returns {function}
 */
export function debounce(func, delayInMs) {
  let timeout = null;

  return function () {
    clearTimeout(timeout);
    const args = arguments;
    const scopedThis = this;

    timeout = setTimeout(() => func.apply(scopedThis, args), delayInMs);
  };
}

/**
 * Returns the number of pixels from the given element to the top of the page.
 * @param {object} element - The element you want to know the distance of.
 * @returns {number}
 */
export function distanceToPageTop(element) {
  return window.pageYOffset + element.getBoundingClientRect().top;
}

/**
 * Evaluates if something is an object.
 * @param {*} param - The thing to evaluate.
 * @returns {boolean}
 */
export function isObject(param) {
  return Object.prototype.toString.call(param) === "[object Object]";
}

/**
 * Returns a mailto string to be used with links.
 * @param {string} subject - The email subject.
 * @param {string} body - The email body.
 * @returns {string}
 */
export function mailtoString({ subject, body }) {
  return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
}

/**
 * Moves an item within an array and returns a new array.
 * @param {Array} array - The array that contains the items to be moved.
 * @param {number} fromIndex - Index of the item to be moved.
 * @param {number} toIndex - Index of where the item should be moved.
 * @returns {Array}
 */
export function moveItem(array, fromIndex, toIndex) {
  // If the indexes are the same then there is no move necessary
  // and we just return a copy of the original.
  if (fromIndex === toIndex) {
    return [...array];
  }

  return array.reduce((newArray, currentItem, currentIndex, oldArray) => {
    // If the current index is the one we want to move from
    // don't add the item we want to move.
    if (currentIndex === fromIndex) {
      return newArray;
    }

    if (fromIndex < toIndex) {
      newArray.push(currentItem);
    }

    // If the current index is the one we want to move to
    // add the item from the old index.
    if (currentIndex === toIndex) {
      newArray.push(oldArray[fromIndex]);
    }

    if (fromIndex > toIndex) {
      newArray.push(currentItem);
    }

    return newArray;
  }, []);
}

/**
 * Throttles a function call by the provided wait time.
 * @param {function} func - The function to throttle.
 * @param {number} waitTime - For how long the function should be throttled.
 * @returns {function} - The throttled function.
 */
export function throttle(func, waitTime) {
  let lastCallTime = Date.now();

  return function () {
    const now = Date.now();
    if (lastCallTime + waitTime - now < 0) {
      func.apply(this, arguments);
      lastCallTime = now;
    }
  };
}

<template>
  <header class="fixed-top d-flex site-header">
    <h1 class="d-none">Veroo TSER</h1>

    <NavMenu />

    <div
      class="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1 bg-white shadow-sm"
    >
      <a class="d-block veroo-logo" href="/" aria-label="$t('logo-link.label')">
        <VerooLogo :width="40" class="mx-3 d-block d-sm-none" />
        <VerooLogo :width="60" class="mx-3 d-none d-sm-block" />
      </a>
      <SearchBar class="flex-grow-1"></SearchBar>

      <div class="mx-2 d-flex">
        <Profile />
        <DisplayFor shipper>
          <NotificationsList />
        </DisplayFor>
        <DisplayFor carrier>
          <NotificationsList />
        </DisplayFor>
      </div>
    </div>
  </header>
</template>

<script>
import DisplayFor from "@/components/DisplayFor";
import NavMenu from "@/components/SiteHeader/NavMenu";
import NotificationsList from "@/features/notifications/components/NotificationsList/NotificationsList";
import SearchBar from "@/features/search/components/SearchBar";
import VerooLogo from "@/components/SiteHeader/VerooLogo";
import Profile from "@/components/SiteHeader/Profile";

export default {
  name: "SiteHeader",
  components: { DisplayFor, NavMenu, NotificationsList, SearchBar, VerooLogo, Profile },
};
</script>

<style lang="scss" scoped>
.site-header {
  z-index: $zindex-above-all;
}
</style>

import { buildTransportsModule } from "@/features/transports/store";
import { getTransportsSearch } from "@/features/search/api";
import { updateQueryString } from "@/core/browser";
import router from "@/router";
import { SORT_BY, SORT_BY_DIRECTION } from "@/features/transports/api";

export const EVENT = {
  RESET_TRIGGERED: "RESET_TRIGGERED",
  SEARCH_TEXT_CHANGED: "SEARCH_TEXT_CHANGED",
};

export const defaultState = (state) => ({
  ...state,
  searchText: "",
});

export const mutations = {
  [EVENT.RESET_TRIGGERED](state) {
    state.page = 0;
    state.searchText = "";
    state.sorting.sortBy = SORT_BY.SCHEDULED;
    state.sorting.sortByDirection = SORT_BY_DIRECTION.ASCENDING;
    state.transports = {};
  },

  [EVENT.SEARCH_TEXT_CHANGED](state, searchText) {
    state.searchText = searchText;
  },
};

export const actions = ({ updateQueryString }) => ({
  async search({ commit, dispatch, rootState }, searchText) {
    if (searchText) {
      if (rootState.currentPage === "SearchResults") {
        updateQueryString(searchText);
      } else {
        router.push({
          path: "/search",
          query: { q: searchText },
        });
      }
      commit(EVENT.SEARCH_TEXT_CHANGED, searchText);
      await dispatch("fetch");
    }
  },

  reset({ commit }) {
    commit(EVENT.RESET_TRIGGERED);
  },
});

/**
 * Builds a store module for search results when searching for transports.
 * @returns {Object} - A Vuex module.
 */
export function buildSearchTransportsModule() {
  const transportModule = buildTransportsModule({
    requestTransports: function (queryParameters, state) {
      queryParameters.criteria = state.searchText;
      return getTransportsSearch(queryParameters);
    },
    extendState: defaultState,
  });

  transportModule.mutations = {
    ...transportModule.mutations,
    ...mutations,
  };

  transportModule.actions = {
    ...transportModule.actions,
    ...actions({ updateQueryString }),
  };

  return transportModule;
}

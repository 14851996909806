export const EVENT = {
  ERROR_MESSAGE_REQUESTED: "ERROR_MESSAGE_REQUESTED",
};

export const state = () => ({
  errorMessage: "",
  errorTitle: "",
});

export const mutations = {
  [EVENT.ERROR_MESSAGE_REQUESTED](state, { errorMessage, errorTitle }) {
    state.errorMessage = errorMessage;
    state.errorTitle = errorTitle;
  },
};

export const actions = () => ({
  displayError({ commit }, { errorMessage, errorTitle }) {
    commit(EVENT.ERROR_MESSAGE_REQUESTED, { errorMessage, errorTitle });
  },
});

/**
 * Builds a store module for global error messages.
 * @returns {Object} - A Vuex module.
 */
export function buildErrorMessagesModule() {
  return {
    namespaced: true,

    state: state(),

    mutations,

    actions: actions(),
  };
}

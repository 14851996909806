export const TRANSPORT_PAGES = {
  ACTION_REQUIRED_TRANSPORTS: "ActionsRequiredTransports",
  AREA_ARRIVED_TRANSPORTS: "AreaArrivedTransports",
  ARRIVED_TRANSPORTS: "ArrivedTransports",
  CANCELLED_TRANSPORTS: "CancelledTransports",
  COMPLETED_TRANSPORTS: "CompletedTransports",
  GPS_LOST_TRANSPORTS: "GpsLostTransports",
  GPS_LOW_TRANSPORTS: "GpsLowTransports",
  WAYPOINT_CONFIRMATION_TRANSPORTS: "WaypointConfirmationTransports",
  GPS_MISSING_TRANSPORTS: "GpsMissingTransports",
  INCOMING_TRANSPORTS: "IncomingTransports",
  MANUALLY_COMPLETED_TRANSPORTS: "ManuallyCompletedTransports",
  ONGOING_TRANSPORTS: "OngoingTransports",
  OVERDUE_TRANSPORTS: "OverdueTransports",
  PLANNED_TRANSPORTS: "PlannedTransports",
  PRESUMABLY_INCOMING_TRANSPORTS: "PresumablyIncomingTransports",
  PRESUMABLY_ONGOING_TRANSPORTS: "PresumablyOngoingTransports",
  SEARCH_RESULTS: "SearchResults",
  TRACKING_ID_MISSING_TRANSPORTS: "TrackingIdMissingTransports",
  LOCATION_ARRIVAL_TRANSPORTS: "LocationArrivalTransports",
  WATCHLIST: "Watchlist",
};

export const PAGES = {
  ...TRANSPORT_PAGES,
  ADMIN_TRANSPORT_CSV_IMPORT: "AdminTransportCsvImport",
  AGREED_SHIPPERS: "AgreedShippers",
  AGREEMENT_REQUEST_HISTORY: "AgreementRequestHistory",
  AGREEMENT_REQUESTS: "AgreementRequests",
  CARRIERS_SETTINGS: "CarriersSettings",
  LIVE_MAP: "LiveMap",
  NO_ORGANIZATION: "NoOrganization",
  NOT_AUTHORIZED: "NotAuthorized",
  NOT_FOUND: "NotFound",
  NOTIFICATIONS_SETTINGS: "NotificationsSettings",
  OUTDATED_TRANSPORT: "OutdatedTransport",
  PUNCTUALITY: "Punctuality",
  CARRIER_PERFORMANCE: "CarrierPerformance",
  VOLUME_REPORT: "Volume Report",
  BILLING_REPORT: "Billing Report",
  TRANSPORT_DETAILS: "TransportDetails",
  TRANSPORT_TRACKING: "TransportWaypointTracking",
  WAYPOINT_DETAILS: "WaypointDetails",
  SERVER_ERROR: "ServerError",
  CARRIER_GPS_SIGNAL_CONNECTION: "CarrierGpsSignalConnection",
  FAQ: "FAQ",
  COCKPIT: "Cockpit",
  WEBHOOKS: "Webhooks",
};

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("features");

export default {
  name: "Feature",

  props: {
    enabledBy: String,
    disabledBy: String,
  },

  computed: {
    ...mapState(["enabled"]),
  },

  render: function () {
    const hasFeatureFlags = Boolean(this.enabled);

    if (!hasFeatureFlags) {
      return null;
    }

    if (this.enabledBy && this.enabled[this.enabledBy] === true) {
      return this.$slots.default;
    }

    if (this.disabledBy && this.enabled[this.disabledBy] !== true) {
      return this.$slots.default;
    }

    return null;
  },
};
</script>

import { getHttpClient } from "@/core/api";

export async function getAllCarriers() {
  const response = await getHttpClient().get("v2/carrier");
  return response.data;
}

export async function getCarriersWithTransports() {
  const response = await getHttpClient().get("v2/carrier/with-transports");
  return response.data;
}

export async function getCarrierGpsReadiness() {
  const response = await getHttpClient().get("v2/carrier/gpsa-carrier-readiness");
  return response.data;
}

export async function getGpsReadinessByCarrier(carrierId) {
  const response = await getHttpClient().get(`v2/carrier/${carrierId}/gpsa-carrier-readiness`);
  return response.data;
}

export async function updatePartnerReference(partner, reference) {
  const response = await getHttpClient().put("v2/carrier/update-partner-reference", { partner, reference });
  return response.data;
}

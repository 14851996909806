const allowUpdates = () => true;

/**
 * Vuex plugin factory that will execute an action in specific  intervals
 * @param { String } action - Action to dispatch.
 * @param { Array<String> } pages - On which pages should the updates trigger. If the page name begins with ! then
 * it means the updates should not trigger on that page.
 * @param { Number } pollingIntervalMs - How often the polling should be done in milliseconds.
 * @param { function } updateAllowed - Function that receives the store and returns a boolean to determine if the updater is allowed to run.
 * @returns {function(*): void} - A plugin function.
 */
export function updaterPlugin({ action, pages, pollingIntervalMs, updateAllowed = allowUpdates }) {
  return (store) => {
    setInterval(() => {
      if (updateAllowed(store)) {
        const { currentPage } = store.state;
        const allowedPages = getAllowedPages(pages);
        const notAllowedPages = getNotAllowedPages(pages);

        if (notAllowedPages.length && !notAllowedPages.includes(currentPage)) {
          store.dispatch(action);
          return;
        }

        if (allowedPages.includes(currentPage)) {
          store.dispatch(action);
        }
      }
    }, pollingIntervalMs);
  };
}

function getAllowedPages(pages) {
  return pages.filter((page) => !page.startsWith("!"));
}

function getNotAllowedPages(pages) {
  return pages.filter((page) => page.startsWith("!")).map((page) => page.substr(1));
}

<template>
  <div :style="{ width: `${width}px` }">
    <svg viewBox="0 0 75 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3212 32.2581L18.2315 32.1374C22.6986 31.5339 26.6711 27.0994 26.7079 27.0522L26.729 27.0259L26.7553 26.9997L29.8701 23.893L20.0835 14.1371C19.1417 13.1978 18.1525 12.3581 17.1265 11.6129L0 14.9978L16.8898 31.8435C17.0318 31.9852 17.1739 32.1216 17.3212 32.2581Z"
        :fill="white ? '#FFFFFF' : '#009EE3'"
      />
      <path
        d="M49.5792 5.15317L27.1784 27.7769C27.1784 27.7769 23.0353 32.532 18.1818 33.1971C21.5004 35.9099 23.4242 37.947 26.3747 39.2982C28.4281 40.2408 34.6246 41.1049 39.9603 35.7161L75.3246 0L49.5792 5.15317Z"
        :fill="white ? '#FFFFFF' : '#1E73B9'"
      />
    </svg>
  </div>
</template>

<script>
const DEFAULT_WIDTH = 75;
const DEFAULT_HEIGHT = 40;
const ASPECT_RATIO = DEFAULT_WIDTH / DEFAULT_HEIGHT;

export default {
  name: "VerooLogo",
  props: {
    white: Boolean,
    width: { type: Number, default: DEFAULT_WIDTH },
  },
  computed: {
    height() {
      return this.width / ASPECT_RATIO;
    },
  },
};
</script>

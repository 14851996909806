<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.833374 0.833334L19.1667 19.1667"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <ellipse cx="9.99996" cy="16.6667" rx="0.833333" ry="0.833333" fill="currentColor" />
    <path
      d="M13.9333 9.21667C14.6159 9.54979 15.2542 9.96691 15.8333 10.4583"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.16663 10.4583C5.4017 9.42569 6.88819 8.73851 8.47496 8.46667"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.92505 4.20833C12.5324 3.91767 16.1028 5.10583 18.8167 7.5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.18335 7.5C2.33932 6.47821 3.66476 5.66602 5.10002 5.1"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.10828 13.425C8.8423 12.1931 11.1659 12.1931 12.8999 13.425"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "NoTrackingIcon",

  props: {
    size: { type: String, default: "100%" },
  },
};
</script>

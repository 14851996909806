import { TRANSPORT_PAGES } from "@/router/pageNames";
import { ORG_ROLE } from "@/core/organization";

export const defaultColumnsOrder = ({ orgRole }) => {
  const carrierOrShipperKey = orgRole === ORG_ROLE.CARRIER ? "shipper" : "carrier";

  return [
    { key: "phase" },
    { key: "punctuality" },
    { key: "actionRequired" },
    { key: "scheduledDate" },
    { key: "nextScheduled" },
    { key: "cancelledAt" },
    { key: "eta" },
    { key: "nextEta" },
    { key: "ata" },
    { key: "from" },
    { key: "nextAddress" },
    { key: "to" },
    { key: carrierOrShipperKey },
    { key: "reference" },
    { key: "waypoints" },
    { key: "trackingId" },
    { key: "gpsSignalIndicator" },
    { key: "actions" },
  ];
};

export function defaultColumnsVisibility({ orgRole, pageName }) {
  const carrierOrShipperKey = orgRole === ORG_ROLE.CARRIER ? "shipper" : "carrier";

  switch (pageName) {
    case TRANSPORT_PAGES.ACTION_REQUIRED_TRANSPORTS:
      return [
        { key: "phase", enabled: true },
        { key: "punctuality", enabled: false },
        { key: "actionRequired", enabled: true },
        { key: "scheduledDate", enabled: false, sortable: true },
        { key: "nextScheduled", enabled: true, sortable: true },
        { key: "eta", enabled: false, sortable: true },
        { key: "nextEta", enabled: true, sortable: true },
        { key: "from", enabled: true },
        { key: "nextAddress", enabled: true },
        { key: "to", enabled: true },
        { key: carrierOrShipperKey, enabled: false },
        { key: "reference", enabled: true },
        { key: "waypoints", enabled: true },
        { key: "trackingId", enabled: true },
        { key: "gpsSignalIndicator", enabled: true },
        { key: "actions", enabled: true },
      ];

    case TRANSPORT_PAGES.TRACKING_ID_MISSING_TRANSPORTS:
    case TRANSPORT_PAGES.GPS_MISSING_TRANSPORTS:
      return [
        { key: "phase", enabled: true },
        { key: "punctuality", enabled: false },
        { key: "actionRequired", enabled: true },
        { key: "scheduledDate", enabled: false, sortable: true },
        { key: "nextScheduled", enabled: true, sortable: true },
        { key: "from", enabled: true },
        { key: "nextAddress", enabled: true },
        { key: "to", enabled: true },
        { key: carrierOrShipperKey, enabled: false },
        { key: "reference", enabled: true },
        { key: "trackingId", enabled: true },
        { key: "actions", enabled: true },
      ];

    case TRANSPORT_PAGES.GPS_LOW_TRANSPORTS:
      return [
        { key: "phase", enabled: true },
        { key: "punctuality", enabled: false },
        { key: "actionRequired", enabled: true },
        { key: "scheduledDate", enabled: false, sortable: true },
        { key: "nextScheduled", enabled: true, sortable: true },
        { key: "eta", enabled: false, sortable: true },
        { key: "nextEta", enabled: true, sortable: true },
        { key: "from", enabled: true },
        { key: "nextAddress", enabled: true },
        { key: "to", enabled: true },
        { key: carrierOrShipperKey, enabled: false },
        { key: "reference", enabled: true },
        { key: "waypoints", enabled: true },
        { key: "trackingId", enabled: true },
        { key: "gpsSignalIndicator", enabled: false },
        { key: "actions", enabled: true },
      ];

    case TRANSPORT_PAGES.GPS_LOST_TRANSPORTS:
      return [
        { key: "phase", enabled: true },
        { key: "punctuality", enabled: false },
        { key: "actionRequired", enabled: true },
        { key: "scheduledDate", enabled: false, sortable: true },
        { key: "nextScheduled", enabled: true, sortable: true },
        { key: "eta", enabled: false, sortable: true },
        { key: "nextEta", enabled: true, sortable: true },
        { key: "from", enabled: true },
        { key: "nextAddress", enabled: true },
        { key: "to", enabled: true },
        { key: carrierOrShipperKey, enabled: false },
        { key: "reference", enabled: true },
        { key: "waypoints", enabled: false },
        { key: "trackingId", enabled: true },
        { key: "gpsSignalIndicator", enabled: false },
        { key: "actions", enabled: true },
      ];

    case TRANSPORT_PAGES.WAYPOINT_CONFIRMATION_TRANSPORTS:
      return [
        { key: "phase", enabled: true },
        { key: "punctuality", enabled: true },
        { key: "actionRequired", enabled: true },
        { key: "scheduledDate", enabled: false, sortable: true },
        { key: "nextScheduled", enabled: true, sortable: true },
        { key: "eta", enabled: false, sortable: true },
        { key: "nextEta", enabled: true, sortable: true },
        { key: "from", enabled: true },
        { key: "nextAddress", enabled: true },
        { key: "to", enabled: true },
        { key: carrierOrShipperKey, enabled: false },
        { key: "reference", enabled: true },
        { key: "waypoints", enabled: true },
        { key: "trackingId", enabled: true },
        { key: "gpsSignalIndicator", enabled: false },
        { key: "actions", enabled: true },
      ];

    case TRANSPORT_PAGES.PLANNED_TRANSPORTS:
      return [
        { key: "scheduledDate", enabled: false, sortable: true },
        { key: "nextScheduled", enabled: true, sortable: true },
        { key: "from", enabled: false },
        { key: "nextAddress", enabled: true },
        { key: "to", enabled: true },
        { key: carrierOrShipperKey, enabled: false },
        { key: "reference", enabled: true },
        { key: "waypoints", enabled: true },
        { key: "trackingId", enabled: true },
        { key: "gpsSignalIndicator", enabled: true },
        { key: "actions", enabled: true },
      ];

    case TRANSPORT_PAGES.INCOMING_TRANSPORTS:
    case TRANSPORT_PAGES.ONGOING_TRANSPORTS:
      return [
        { key: "punctuality", enabled: true },
        { key: "actionRequired", enabled: true },
        { key: "scheduledDate", enabled: false, sortable: true },
        { key: "nextScheduled", enabled: true, sortable: true },
        { key: "eta", enabled: false, sortable: true },
        { key: "nextEta", enabled: true, sortable: true },
        { key: "from", enabled: false },
        { key: "nextAddress", enabled: true },
        { key: "to", enabled: true },
        { key: carrierOrShipperKey, enabled: false },
        { key: "reference", enabled: true },
        { key: "waypoints", enabled: true },
        { key: "trackingId", enabled: true },
        { key: "gpsSignalIndicator", enabled: true },
        { key: "actions", enabled: true },
      ];

    case TRANSPORT_PAGES.PRESUMABLY_INCOMING_TRANSPORTS:
    case TRANSPORT_PAGES.PRESUMABLY_ONGOING_TRANSPORTS:
      return [
        { key: "actionRequired", enabled: true },
        { key: "scheduledDate", enabled: false, sortable: true },
        { key: "nextScheduled", enabled: true, sortable: true },
        { key: "eta", enabled: false, sortable: true },
        { key: "nextEta", enabled: true, sortable: true },
        { key: "from", enabled: false },
        { key: "nextAddress", enabled: true },
        { key: "to", enabled: true },
        { key: carrierOrShipperKey, enabled: false },
        { key: "reference", enabled: true },
        { key: "waypoints", enabled: true },
        { key: "trackingId", enabled: true },
        { key: "gpsSignalIndicator", enabled: true },
        { key: "actions", enabled: true },
      ];

    case TRANSPORT_PAGES.COMPLETED_TRANSPORTS:
      return [
        { key: "punctuality", enabled: true },
        { key: "actionRequired", enabled: false },
        { key: "scheduledDate", enabled: true, sortable: true },
        { key: "ata", enabled: true, sortable: true },
        { key: "from", enabled: true },
        { key: "to", enabled: true },
        { key: carrierOrShipperKey, enabled: false },
        { key: "reference", enabled: true },
        { key: "waypoints", enabled: true },
        { key: "trackingId", enabled: true },
        { key: "actions", enabled: false },
      ];

    case TRANSPORT_PAGES.AREA_ARRIVED_TRANSPORTS:
    case TRANSPORT_PAGES.ARRIVED_TRANSPORTS:
    case TRANSPORT_PAGES.MANUALLY_COMPLETED_TRANSPORTS:
      return [
        { key: "punctuality", enabled: true },
        { key: "actionRequired", enabled: false },
        { key: "scheduledDate", enabled: true, sortable: true },
        { key: "ata", enabled: true, sortable: true },
        { key: "from", enabled: true },
        { key: "to", enabled: true },
        { key: carrierOrShipperKey, enabled: false },
        { key: "reference", enabled: true },
        { key: "waypoints", enabled: true },
        { key: "trackingId", enabled: true },
        { key: "actions", enabled: true },
      ];

    case TRANSPORT_PAGES.OVERDUE_TRANSPORTS:
      return [
        { key: "actionRequired", enabled: false },
        { key: "scheduledDate", enabled: true, sortable: true },
        { key: "ata", enabled: true, sortable: true },
        { key: "from", enabled: true },
        { key: "to", enabled: true },
        { key: carrierOrShipperKey, enabled: false },
        { key: "reference", enabled: true },
        { key: "waypoints", enabled: true },
        { key: "trackingId", enabled: true },
        { key: "actions", enabled: true },
      ];

    case TRANSPORT_PAGES.CANCELLED_TRANSPORTS:
      return [
        { key: "scheduledDate", enabled: true, sortable: true },
        { key: "cancelledAt", enabled: true, sortable: true },
        { key: "from", enabled: true },
        { key: "to", enabled: true },
        { key: carrierOrShipperKey, enabled: true },
        { key: "reference", enabled: true },
        { key: "waypoints", enabled: true },
        { key: "trackingId", enabled: true },
        { key: "actions", enabled: true },
      ];

    case TRANSPORT_PAGES.SEARCH_RESULTS:
    case TRANSPORT_PAGES.WATCHLIST:
      return [
        { key: "phase", enabled: true },
        { key: "punctuality", enabled: true },
        { key: "actionRequired", enabled: true },
        { key: "scheduledDate", enabled: false, sortable: true },
        { key: "nextScheduled", enabled: true, sortable: true },
        { key: "cancelledAt", enabled: true, sortable: true },
        { key: "from", enabled: true },
        { key: "nextAddress", enabled: true },
        { key: "to", enabled: true },
        { key: "eta", enabled: false, sortable: true },
        { key: "nextEta", enabled: true, sortable: true },
        { key: "ata", enabled: true, sortable: true },
        { key: carrierOrShipperKey, enabled: false },
        { key: "waypoints", enabled: true },
        { key: "reference", enabled: true },
        { key: "trackingId", enabled: true },
        { key: "gpsSignalIndicator", enabled: true },
        { key: "actions", enabled: true },
      ];

    case TRANSPORT_PAGES.LOCATION_ARRIVAL_TRANSPORTS:
      return [
        { key: "punctuality", enabled: true },
        { key: "actionRequired", enabled: false },
        { key: "scheduledDate", enabled: true, sortable: false },
        { key: "eta", enabled: true, sortable: false },
        { key: carrierOrShipperKey, enabled: true },
        { key: "reference", enabled: true },
        { key: "trackingId", enabled: true },
        { key: "gpsSignalIndicator", enabled: true },
      ];

    default:
      return [
        { key: "phase", enabled: false },
        { key: "punctuality", enabled: true },
        { key: "actionRequired", enabled: true },
        { key: "scheduledDate", enabled: false, sortable: true },
        { key: "nextScheduled", enabled: true, sortable: true },
        { key: "eta", enabled: false, sortable: true },
        { key: "nextEta", enabled: true, sortable: true },
        { key: "ata", enabled: false, sortable: true },
        { key: "from", enabled: true },
        { key: "nextAddress", enabled: true },
        { key: "to", enabled: true },
        { key: carrierOrShipperKey, enabled: false },
        { key: "reference", enabled: true },
        { key: "waypoints", enabled: true },
        { key: "trackingId", enabled: true },
        { key: "gpsSignalIndicator", enabled: true },
        { key: "actions", enabled: true },
      ];
  }
}

<template>
  <button @click="$emit('click')" :aria-label="$t('common.clear')">
    <Icon type="x" size="12" line-height="0.5" />
  </button>
</template>

<script>
import Icon from "@/components/Icons/Icon";

export default {
  name: "ClearInputButton",

  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
button {
  align-items: center;
  background-color: $white;
  border: 1px solid $input-border-color;
  border-radius: 50%;
  color: map-get($theme-colors, "primary");
  display: flex;
  height: 1.125rem;
  justify-content: center;
  width: 1.125rem;
}

button:focus,
button:hover {
  box-shadow: $box-shadow-sm;
  outline: 0;
}
</style>

<template>
  <svg :width="size" :height="size" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.4375 3.125H15.6875C16.2398 3.125 16.6875 3.57272 16.6875 4.125V14.625C16.6875 15.1773 16.2398 15.625 15.6875 15.625H1.9375"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="6.93137" cy="18.9311" r="2.49387" stroke="currentColor" stroke-width="2" />
    <circle cx="19.1814" cy="18.9311" r="2.49387" stroke="currentColor" stroke-width="2" />
    <mask id="path-4-inside-1" fill="white">
      <path
        d="M15.6875 5.25H22L24.1818 8.11378C24.7126 8.81044 25 9.66205 25 10.5379V15.625C25 16.1773 24.5523 16.625 24 16.625H15.6875V5.25Z"
      />
    </mask>
    <path
      d="M15.6875 5.25V3.25C14.5829 3.25 13.6875 4.14543 13.6875 5.25H15.6875ZM15.6875 16.625H13.6875V18.625H15.6875V16.625ZM22 5.25L23.5909 4.03796C23.2126 3.54144 22.6242 3.25 22 3.25V5.25ZM24.1818 8.11378L25.7727 6.90174L24.1818 8.11378ZM23 10.5379V15.625H27V10.5379H23ZM24 14.625H15.6875V18.625H24V14.625ZM17.6875 16.625V5.25H13.6875V16.625H17.6875ZM15.6875 7.25H22V3.25H15.6875V7.25ZM20.4091 6.46204L22.5909 9.32582L25.7727 6.90174L23.5909 4.03796L20.4091 6.46204ZM23 15.625C23 15.0727 23.4477 14.625 24 14.625V18.625C25.6569 18.625 27 17.2819 27 15.625H23ZM27 10.5379C27 9.22414 26.5688 7.94673 25.7727 6.90174L22.5909 9.32582C22.8563 9.67415 23 10.1 23 10.5379H27Z"
      fill="currentColor"
      mask="url(#path-4-inside-1)"
    />
    <path
      d="M4.36279 7.3125H10.4253"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.71265 11.375H8.77515"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: { type: String, value: "20" },
  },
};
</script>

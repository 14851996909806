<template>
  <div v-if="isAuthenticated">
    <b-dropdown
      id="user-menu"
      toggle-class="text-decoration-none"
      variant="link"
      no-caret
      aria-label="$t('profile.button-aria-label')"
      ref="ddown"
    >
      <template v-slot:button-content>
        <Avatar :name="avatarName" :url="avatarUrl"></Avatar>
      </template>

      <div class="dropdown-body p-0">
        <div class="px-3 py-4">
          <div class="d-flex mb-3">
            <Avatar class="flex-shrink-0" :name="avatarName" large :url="avatarUrl"></Avatar>
            <div class="flex-grow-1 px-2 pt-1 name">
              <p class="text-dark m-0">{{ fullName }}</p>
              <p class="text-gray-400 m-0 text-break">{{ email }}</p>
            </div>
          </div>
          <div class="d-flex justify-content-around">
            <Feature enabled-by="faq">
              <router-link :to="{ name: PAGES.FAQ }" @click.native="hideUserMenu">{{ $t("common.faq") }}</router-link>
            </Feature>
            <a href="#" @click="logout" class="font-weight-bold">
              <Icon size="16" type="log-out"></Icon>
              {{ $t("profile.logout") }}
            </a>
          </div>
        </div>

        <DisplayFor shipper>
          <div>
            <hr class="m-0" />

            <div class="px-3 pt-3 pb-2">
              <router-link :to="{ name: PAGES.WEBHOOKS }" @click.native="hideUserMenu">
                <WebhookIcon />
                <span class="pl-2">{{ $t("webhooks.manage-subscriptions") }}</span>
              </router-link>
            </div>
          </div>
        </DisplayFor>

        <hr class="m-0" />

        <div class="d-flex align-items-center justify-content-around px-3 pt-3 pb-2">
          <a href="https://www.veroo.io/privacy-policy" target="_blank" class="mr-5">{{
            $t("profile.privacy-policy")
          }}</a>
          <a href="https://veroo.io/terms-of-use" target="_blank">{{ $t("profile.terms-of-use") }}</a>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";
import { PAGES } from "@/router/pageNames";
import Avatar from "@/components/Avatar";
import Icon from "@/components/Icons/Icon";
import Feature from "@/features/featureToggle/components/Feature";
import WebhookIcon from "@/components/Icons/WebhookIcon.vue";
import DisplayFor from "@/components/DisplayFor.vue";

const { mapActions: mapAuthActions, mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "Profile",

  components: {
    DisplayFor,
    WebhookIcon,
    Avatar,
    Icon,
    Feature,
  },

  data() {
    return {
      PAGES,
    };
  },

  methods: {
    ...mapAuthActions(["logout"]),

    hideUserMenu() {
      this.$refs.ddown.hide();
    },
  },

  computed: {
    ...mapGetters(["profile"]),
    ...mapAuthGetters(["isAuthenticated"]),

    avatarUrl() {
      return this.profile.picture;
    },

    email() {
      return this.profile.email;
    },

    fullName() {
      const fullName = `${this.profile.givenName ?? ""} ${this.profile.familyName ?? ""}`;

      if (fullName.length > 1) {
        return fullName;
      }

      // Don't display a fullname if not given
      return "";
    },

    avatarName() {
      const parts = [this.profile.givenName, this.profile.familyName].filter((part) => part);

      if (parts.length === 2) {
        return parts.join(" ");
      }

      // Use email as fallback
      return this.profile.email;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-body {
  width: 22rem;
}
</style>

/**
 * Module for working with the transport-service API.
 */

import axios from "axios";
import Qs from "qs";
import { isAuth0Enabled } from "@/features/auth";
import { capitalize, isObject } from "@/core/js";
import Logger from "@/core/logger";
const logger = Logger.getInstance();

let httpClient = null;
let httpClientMock = null;

export function getHttpClient() {
  return httpClient;
}

export function getHttpClientMock(clientConfig) {
  if (!httpClientMock) {
    httpClientMock = axios.create({ ...getHttpClientConfig(), ...clientConfig });
  }
  return httpClientMock;
}

export function setupHttpClient({ getAccessToken, onBadRequest, onUnauthorized }) {
  httpClient = axios.create(getHttpClientConfig());

  httpClient.interceptors.request.use(
    function onRequest(config) {
      const accessToken = getAccessToken();
      if (isAuth0Enabled() && accessToken) {
        config.headers.authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  httpClient.interceptors.response.use(
    (response) => response,
    async function onError(error) {
      const status = error.response?.status;

      if (status === 400) {
        onBadRequest();
      } else if (status === 401) {
        onUnauthorized();
      }

      if (status >= 500) {
        logger.error(error.message, error);
      } else if (status >= 400) {
        logger.warn(error.message, error);
      }

      return Promise.reject(error);
    }
  );
}

export function buildQueryParams(queryParameters) {
  return Object.assign({}, { page: 0, limit: 50 }, queryParameters);
}

export function filtersToQueryParameters(filters, queryParameters) {
  for (const filterKey of Object.keys(filters)) {
    const filterQueryParameter = {};

    const splitFilterObjectToParameters = () =>
      Object.entries(filters[filterKey]).forEach((entry) => {
        const entryKey = capitalize(entry[0]);
        filterQueryParameter[`${filterKey}${entryKey}`] = entry[1];
      });

    isObject(filters[filterKey])
      ? splitFilterObjectToParameters()
      : (filterQueryParameter[filterKey] = filters[filterKey]);

    if (filterQueryParameter) {
      queryParameters = {
        ...queryParameters,
        ...filterQueryParameter,
      };
    }
  }

  return queryParameters;
}

function getHttpClientConfig() {
  const baseURL = process.env.VUE_APP_API_BASE_URL;
  return {
    baseURL,
    paramsSerializer: {
      // Spring Boot cannot parse query parameter lists made with the default "parameter[]" syntax.
      serialize: (params) => Qs.stringify(params, {arrayFormat: 'repeat'})
    },
  };
}

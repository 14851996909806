<template>
  <div
    class="position-relative"
    aria-label="$t('notifications.list-button-aria-label')"
    v-click-outside="onClickOutside"
  >
    <MenuButton @click="onMenuButtonClick" :has-unread-notifications="hasUnreadNotifications" />

    <section class="position-absolute mt-1 shadow-sm bg-white border" v-if="isOpen">
      <NotificationsListHeader @mark-all-click="onMarkAllButtonClick" @settings-click="onSettingsButtonClick" />

      <ul class="m-0 list-unstyled overflow-auto">
        <NotificationItem
          v-for="(notification, index) in notifications"
          :key="notification.id"
          :notification="notification"
          :show-yesterday="showYesterday(notification, index)"
          @link-click="onLinkClick"
          @mark-click="onMarkButtonClick"
        />
      </ul>
    </section>
  </div>
</template>

<script>
import { isSameDay } from "@/core/date";
import NotificationsListHeader from "@/features/notifications/components/NotificationsList/NotificationsListHeader";
import MenuButton from "@/features/notifications/components/NotificationsList/MenuButton";
import NotificationItem from "@/features/notifications/components/NotificationsList/NotificationItem";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters } = createNamespacedHelpers("notifications");

export default {
  components: { NotificationItem, NotificationsListHeader, MenuButton },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    ...mapState({
      notifications(state) {
        return state.notifications;
      },
    }),

    ...mapGetters(["hasUnreadNotifications"]),
  },

  methods: {
    ...mapActions(["updateAllNotifications", "updateNotification"]),

    onLinkClick(notification) {
      this.updateNotification({ notificationId: notification.id, isRead: true });
      this.isOpen = false;
    },

    onMarkAllButtonClick() {
      this.updateAllNotifications({ isRead: true });
    },

    onMarkButtonClick(notification) {
      this.updateNotification({ notificationId: notification.id, isRead: !notification.read });
    },

    onMenuButtonClick() {
      this.isOpen = !this.isOpen;
    },

    onSettingsButtonClick() {
      this.isOpen = false;
    },

    onClickOutside() {
      this.isOpen = false;
    },

    showYesterday(notification, index) {
      const today = new Date();
      const isNotificationFromToday = isSameDay(today, notification.createdAt);
      const isPreviousNotificationFromToday = index > 0 && isSameDay(today, this.notifications[index - 1].createdAt);
      const isFirstNotificationFromOtherDay = !isNotificationFromToday && isPreviousNotificationFromToday;
      return isFirstNotificationFromOtherDay;
    },
  },
};
</script>

<style scoped>
section {
  right: 0;
  width: calc(100vw - 1.25rem);
}

@media (min-width: 500px) {
  section {
    width: 30rem;
  }
}

ul {
  max-height: 23rem;
}
</style>

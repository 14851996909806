<template>
  <Toast no-auto-hide variant="danger" :id="id" :title="title">
    <slot>
      <span v-html="errorMessage"></span>
    </slot>
  </Toast>
</template>

<script>
import Toast from "@/components/Toasts/Toast";

export default {
  name: "ErrorToast",

  components: {
    Toast,
  },

  props: {
    errorMessage: String,
    id: { type: String, required: true },
    title: { type: String, required: true },
  },
};
</script>

import { getAllCarriers, getCarriersWithTransports } from "@/features/carriers/api";

export const EVENT = {
  CARRIERS_REQUESTED: "CARRIERS_REQUESTED",
  CARRIERS_WITH_TRANSPORTS_REQUESTED: "CARRIERS_WITH_TRANSPORTS_REQUESTED",
  REQUEST_CARRIERS_FAILED: "REQUEST_CARRIERS_FAILED",
  REQUEST_CARRIERS_SUCCEEDED: "REQUEST_CARRIERS_SUCCEEDED",
  REQUEST_CARRIERS_WITH_TRANSPORTS_FAILED: "REQUEST_CARRIERS_WITH_TRANSPORTS_FAILED",
  REQUEST_CARRIERS_WITH_TRANSPORTS_SUCCEEDED: "REQUEST_CARRIERS_WITH_TRANSPORTS_SUCCEEDED",
};

export const defaultState = () => ({
  carriers: [],
  carriersWithTransports: [],
  fetchingCarriers: false,
  fetchingCarriersFailedError: null,
  fetchingCarriersWithTransports: false,
  fetchingCarriersWithTransportsFailedError: null,
});

export const mutations = {
  [EVENT.CARRIERS_REQUESTED](state) {
    state.fetchingCarriers = true;
  },

  [EVENT.REQUEST_CARRIERS_SUCCEEDED](state, response) {
    state.fetchingCarriersFailedError = null;
    state.carriers = response;
    state.fetchingCarriers = false;
  },

  [EVENT.REQUEST_CARRIERS_FAILED](state, error) {
    state.fetchingCarriers = false;
    state.fetchingCarriersFailedError = error;
  },

  [EVENT.CARRIERS_WITH_TRANSPORTS_REQUESTED](state) {
    state.fetchingCarriersWithTransports = true;
  },

  [EVENT.REQUEST_CARRIERS_WITH_TRANSPORTS_SUCCEEDED](state, response) {
    state.fetchingCarriersWithTransportsFailedError = null;
    state.carriersWithTransports = response;
    state.fetchingCarriersWithTransports = false;
  },

  [EVENT.REQUEST_CARRIERS_WITH_TRANSPORTS_FAILED](state, error) {
    state.fetchingCarriersWithTransports = false;
    state.fetchingCarriersWithTransportsFailedError = error;
  },
};

export const actions = ({ getAllCarriers, getCarriersWithTransports }) => ({
  async fetch({ commit }) {
    commit(EVENT.CARRIERS_REQUESTED);
    try {
      const response = await getAllCarriers();
      commit(EVENT.REQUEST_CARRIERS_SUCCEEDED, response);
    } catch (error) {
      commit(EVENT.REQUEST_CARRIERS_FAILED, error);
    }
  },

  async fetchCarriersWithTransports({ commit }) {
    commit(EVENT.CARRIERS_WITH_TRANSPORTS_REQUESTED);
    try {
      const response = await getCarriersWithTransports();
      commit(EVENT.REQUEST_CARRIERS_WITH_TRANSPORTS_SUCCEEDED, response);
    } catch (error) {
      commit(EVENT.REQUEST_CARRIERS_WITH_TRANSPORTS_FAILED, error);
    }
  },
});

/**
 * Builds a store module for storing transport carriers.
 * @returns {Object} - A Vuex module.
 */
export function buildCarriersModule() {
  return {
    namespaced: true,

    state: defaultState(),

    mutations,

    actions: actions({ getAllCarriers, getCarriersWithTransports }),

    getters: {},
  };
}

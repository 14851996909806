<template>
  <svg :width="size" :height="size" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.0964 12.9733C18.0066 17.4448 13.4983 20.1862 9.02678 19.0964C4.55529 18.0066 1.81389 13.4982 2.90369 9.02676C3.9935 4.55527 8.50181 1.81388 12.9733 2.90368C15.3378 3.47996 17.2185 5.01214 18.3048 6.9893"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 6V11L13.5 13.5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M19.2814 2.66675V7.61466H14.3335" stroke="currentColor" stroke-width="1.5" />
  </svg>
</template>

<script>
export default {
  props: {
    size: { type: String, value: "20" },
  },
};
</script>

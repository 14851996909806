import { getFeatures } from "@/features/featureToggle/api";

export const EVENT = {
  FEATURES_REQUESTED: "FEATURES_REQUESTED",
  REQUEST_FEATURES_FAILED: "REQUEST_FEATURES_FAILED",
  REQUEST_FEATURES_SUCCEEDED: "REQUEST_FEATURES_SUCCEEDED",
};

export const defaultState = () => ({
  enabled: {},
  fetching: false,
  fetchingFailedError: null,
});

export const mutations = {
  [EVENT.FEATURES_REQUESTED](state) {
    state.fetching = true;
  },

  [EVENT.REQUEST_FEATURES_SUCCEEDED](state, response) {
    state.fetchingFailedError = null;

    const features = {};
    for (const feature of response) {
      features[feature] = true;
    }

    state.enabled = features;
    state.fetching = false;
  },

  [EVENT.REQUEST_FEATURES_FAILED](state, error) {
    state.fetching = false;
    state.fetchingFailedError = error;
  },
};

export const actions = ({ getFeatures }) => ({
  async fetchFeatures({ commit }) {
    commit(EVENT.FEATURES_REQUESTED);

    try {
      const response = await getFeatures();
      commit(EVENT.REQUEST_FEATURES_SUCCEEDED, response);
    } catch (error) {
      commit(EVENT.REQUEST_FEATURES_FAILED, error);
    }
  },
});

/**
 * Builds a store module of enabled features.
 * Used for feature toggling.
 * @returns {Object} - A Vuex module.
 */
export function buildFeaturesModule() {
  return {
    namespaced: true,

    state: defaultState(),

    mutations,

    actions: actions({ getFeatures }),
  };
}

<template>
  <li class="d-flex justify-content-between pl-2" :class="itemBgClass">
    <router-link
      class="d-flex flex-column flex-grow-1 text-gray-400"
      :to="{ name: 'TransportDetails', params: { transportId: notification.transportId } }"
      @click.native="onLinkClick"
    >
      <p class="mb-0 pl-2 pt-2 font-weight-bold font-size-sm text-uppercase" v-if="showYesterday">
        {{ $t("common.yesterday") }}
      </p>

      <div class="d-flex flex-grow-1">
        <div class="p-2">
          <Icon size="16" :color="data.iconColor" :type="data.iconType" />
        </div>

        <div class="flex-grow-1 py-2">
          <p class="mb-0 font-weight-bold text-dark text-uppercase">
            {{ data.title }}
          </p>
          <p class="mb-0" v-if="notification.trackingId">
            {{ $t("common.tracking-id") }}: {{ notification.trackingId }}
          </p>
          <p class="mb-0" v-else-if="notification.reference">
            {{ $t("common.reference") }}: {{ notification.reference }}
          </p>
        </div>
      </div>
    </router-link>

    <InlineActionsButton icon-size="20" dropleft>
      <b-dropdown-item-button @click="onMarkButtonClick" class="context-menu-item">
        <Icon type="check-circle" class="mr-2" />
        {{ $t(`notifications-list.${notification.read ? "mark-as-unread" : "mark-as-read"}`) }}
      </b-dropdown-item-button>
    </InlineActionsButton>
  </li>
</template>

<script>
import i18n from "@/i18n";
import { NOTIFICATION_TYPE } from "@/features/notifications";
import Icon from "@/components/Icons/Icon";
import InlineActionsButton from "@/components/Buttons/InlineActionsButton";

export default {
  name: "NotificationItem",

  components: {
    Icon,
    InlineActionsButton,
  },

  props: {
    notification: { type: Object, default: () => {} },
    showYesterday: Boolean,
  },

  computed: {
    data() {
      let data = {};

      switch (this.notification.type) {
        case NOTIFICATION_TYPE.TRANSPORT_APPROACHING_WAYPOINT:
          data = {
            iconColor: "primary",
            iconType: "map-pin",
            title: i18n.t("notifications-list.title.transport-approaching-waypoint"),
          };
          break;
        case NOTIFICATION_TYPE.TRANSPORT_COMPLETED:
          data = {
            iconColor: "primary",
            iconType: "check-circle",
            title: i18n.t("notifications-list.title.transport-completed"),
          };
          break;
        case NOTIFICATION_TYPE.TRANSPORT_DELAYED:
          data = {
            iconColor: "warning",
            iconType: "alert-triangle",
            title: i18n.t("notifications-list.title.transport-delayed"),
          };
          break;
        case NOTIFICATION_TYPE.TRANSPORT_GPS_STATUS_LOW:
          data = {
            iconColor: "warning",
            iconType: "alert-triangle",
            title: i18n.t("notifications-list.title.gps-low"),
          };
          break;
        case NOTIFICATION_TYPE.TRANSPORT_GPS_STATUS_LOST:
          data = {
            iconColor: "warning",
            iconType: "alert-triangle",
            title: i18n.t("notifications-list.title.gps-lost"),
          };
          break;
        case NOTIFICATION_TYPE.TRANSPORT_GPS_MISSING:
          data = {
            iconColor: "warning",
            iconType: "alert-triangle",
            title: i18n.t("notifications-list.title.gps-missing"),
          };
          break;
        case NOTIFICATION_TYPE.TRANSPORT_TRACKING_ID_MISSING:
          data = {
            iconColor: "warning",
            iconType: "alert-triangle",
            title: i18n.t("notifications-list.title.tracking-id-missing"),
          };
          break;
      }

      if (this.notification.read) {
        data.iconColor = "gray-600";
      }

      return data;
    },

    itemBgClass() {
      return this.notification.read ? "bg-gray-100 read-border-bottom" : "border-bottom";
    },
  },

  methods: {
    onLinkClick() {
      this.$emit("link-click", this.notification);
    },

    onMarkButtonClick() {
      this.$emit("mark-click", this.notification);
    },
  },
};
</script>

<style scoped>
.read-border-bottom {
  border-bottom: 1px solid #ccc;
}
</style>

import { buildQueryParams, getHttpClient } from "@/core/api";

export async function getWatchedTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/watchlist/details", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getWatchlist() {
  const response = await getHttpClient().get(`v2/transports/watchlist`);
  return response.data;
}

export async function addToWatchlist(transportId) {
  const response = await getHttpClient().post(`v2/transports/watchlist/${transportId}`);
  return response.data;
}

export async function removeFromWatchlist(transportId) {
  const response = await getHttpClient().delete(`v2/transports/watchlist/${transportId}`);
  return response.data;
}

import { getTransportById, getTransportGpsSignals } from "@/features/transports/api";
import { getWaypointEvents, getTransportLog, getTransportOnsiteProcessingTimes } from "@/features/transportDetails/api";
import { isTransportCancelled } from "@/core/transport";

export const EVENT = {
  RESET_REQUEST_IN_PROGRESS_TRIGGERED: "RESET_REQUEST_IN_PROGRESS_TRIGGERED",
  RESET_TRIGGERED: "RESET_TRIGGERED",
  REQUEST_GPS_SIGNALS_FAILED: "REQUEST_GPS_SIGNALS_FAILED",
  REQUEST_TRANSPORT_DETAILS_FAILED: "REQUEST_TRANSPORT_DETAILS_FAILED",
  REQUEST_WAYPOINT_EVENTS_FAILED: "REQUEST_WAYPOINT_EVENTS_FAILED",
  REQUEST_TRANSPORT_LOG_FAILED: "REQUEST_TRANSPORT_LOG_FAILED",
  REQUEST_GPS_SIGNALS_SUCCEEDED: "REQUEST_GPS_SIGNALS_SUCCEEDED",
  REQUEST_TRANSPORT_DETAILS_SUCCEEDED: "REQUEST_TRANSPORT_DETAILS_SUCCEEDED",
  REQUEST_WAYPOINT_EVENTS_SUCCEEDED: "REQUEST_WAYPOINT_EVENTS_SUCCEEDED",
  REQUEST_TRANSPORT_LOG_SUCCEEDED: "REQUEST_TRANSPORT_LOG_SUCCEEDED",
  REQUEST_TRANSPORT_ONSITE_PROCESSING_TIMES_SUCCEEDED: "REQUEST_TRANSPORT_ONSITE_PROCESSING_TIMES_SUCCEEDED",
  REQUEST_TRANSPORT_ONSITE_PROCESSING_TIMES_FAILED: "REQUEST_TRANSPORT_ONSITE_PROCESSING_TIMES_FAILED",
  REQUESTS_STARTED: "REQUESTS_STARTED",
  UPDATE_FIRST_LOAD_TRIGGERED: "UPDATE_FIRST_LOAD_TRIGGERED",
};

export const defaultState = () => ({
  gpsSignals: [],
  gpsSignalsError: null,
  isFirstLoad: true,
  isRequestInProgress: false,
  transportDetails: {},
  transportDetailsError: null,
  waypointEventsError: null,
  waypointsEvents: {},
  transportLogError: null,
  transportLog: [],
  transportOnsiteProcessingError: null,
  transportOnsiteProcessingTimes: [],
});

export const mutations = {
  [EVENT.RESET_REQUEST_IN_PROGRESS_TRIGGERED](state) {
    state.isRequestInProgress = false;
  },

  [EVENT.RESET_TRIGGERED](state) {
    Object.assign(state, defaultState());
  },

  [EVENT.REQUEST_GPS_SIGNALS_FAILED](state, error) {
    state.gpsSignalsError = error;
  },

  [EVENT.REQUEST_TRANSPORT_DETAILS_FAILED](state, error) {
    state.transportDetailsError = error;
  },

  [EVENT.REQUEST_WAYPOINT_EVENTS_FAILED](state, error) {
    state.waypointEventsError = error;
  },

  [EVENT.REQUEST_TRANSPORT_LOG_FAILED](state, error) {
    state.transportLogError = error;
  },

  [EVENT.REQUEST_GPS_SIGNALS_SUCCEEDED](state, response) {
    state.gpsSignals = response;
    state.gpsSignalsError = null;
  },

  [EVENT.REQUEST_TRANSPORT_DETAILS_SUCCEEDED](state, response) {
    state.transportDetails = response;
    state.transportDetailsError = null;
  },

  [EVENT.REQUEST_WAYPOINT_EVENTS_SUCCEEDED](state, { events, waypointId }) {
    state.waypointsEvents = {
      ...state.waypointsEvents,
      [waypointId]: events,
    };
    state.waypointEventsError = null;
  },

  [EVENT.REQUEST_TRANSPORT_LOG_SUCCEEDED](state, response) {
    state.transportLog = response;
    state.transportLogError = null;
  },

  [EVENT.REQUEST_TRANSPORT_ONSITE_PROCESSING_TIMES_SUCCEEDED](state, response) {
    state.transportOnsiteProcessingTimes = response;
    state.transportOnSiteProcessingError = null;
  },

  [EVENT.REQUESTS_STARTED](state) {
    state.isRequestInProgress = true;
  },

  [EVENT.UPDATE_FIRST_LOAD_TRIGGERED](state) {
    state.isFirstLoad = false;
  },
};

export const actions = ({
  getTransportById,
  getTransportGpsSignals,
  getWaypointEvents,
  getTransportLog,
  getTransportOnsiteProcessingTimes,
}) => ({
  async getGpsSignals({ commit }, transportId) {
    try {
      const gpsSignals = await getTransportGpsSignals(transportId);
      commit(EVENT.REQUEST_GPS_SIGNALS_SUCCEEDED, gpsSignals);
    } catch (error) {
      commit(EVENT.REQUEST_GPS_SIGNALS_FAILED, error);
    }
  },

  async getTransportDetails({ commit, dispatch }, transportId) {
    commit(EVENT.REQUESTS_STARTED);

    let transportDetails = null;

    try {
      transportDetails = await getTransportById({ transportId, includeCancelled: true });
      commit(EVENT.REQUEST_TRANSPORT_DETAILS_SUCCEEDED, transportDetails);
    } catch (error) {
      commit(EVENT.REQUEST_TRANSPORT_DETAILS_FAILED, error);
      commit(EVENT.RESET_REQUEST_IN_PROGRESS_TRIGGERED);
      return;
    }

    await dispatch("getLog", transportId);
    await dispatch("getOnsiteProcessingTimes", transportId);

    if (!isTransportCancelled(transportDetails)) {
      const transportId = transportDetails.id;
      const { waypoints } = transportDetails;
      await dispatch("getGpsSignals", transportId);

      if (waypoints) {
        await Promise.all(
          waypoints.map((waypoint) => dispatch("getWaypointEvents", { transportId, waypointId: waypoint.id }))
        );
      }
    }

    commit(EVENT.UPDATE_FIRST_LOAD_TRIGGERED);
    commit(EVENT.RESET_REQUEST_IN_PROGRESS_TRIGGERED);
  },

  async getWaypointEvents({ commit }, { transportId, waypointId }) {
    try {
      const events = await getWaypointEvents({ transportId, waypointId });
      commit(EVENT.REQUEST_WAYPOINT_EVENTS_SUCCEEDED, {
        events,
        waypointId,
      });
    } catch (error) {
      commit(EVENT.REQUEST_WAYPOINT_EVENTS_FAILED, error);
    }
  },

  async getLog({ commit }, transportId) {
    try {
      const transportLog = await getTransportLog(transportId);
      commit(EVENT.REQUEST_TRANSPORT_LOG_SUCCEEDED, transportLog);
    } catch (error) {
      commit(EVENT.REQUEST_TRANSPORT_LOG_FAILED, error);
    }
  },

  async getOnsiteProcessingTimes({ commit }, transportId) {
    try {
      const onsiteProcessingTimes = await getTransportOnsiteProcessingTimes(transportId);
      commit(EVENT.REQUEST_TRANSPORT_ONSITE_PROCESSING_TIMES_SUCCEEDED, onsiteProcessingTimes);
    } catch (error) {
      commit(EVENT.REQUEST_TRANSPORT_ONSITE_PROCESSING_TIMES_FAILED, error);
    }
  },

  async refresh({ dispatch, state }) {
    const { transportDetails } = state;

    if (transportDetails && transportDetails.id) {
      dispatch("getTransportDetails", transportDetails.id);
    }
  },

  reset({ commit }) {
    commit(EVENT.RESET_TRIGGERED);
  },
});

/**
 * Builds a store module for transport details.
 * @returns {Object} - A Vuex module.
 */
export function buildTransportDetailsModule() {
  return {
    namespaced: true,

    state: defaultState(),

    mutations,

    actions: actions({
      getTransportById,
      getTransportGpsSignals,
      getWaypointEvents,
      getTransportLog,
      getTransportOnsiteProcessingTimes,
    }),
  };
}

import { TRANSPORT_PAGES } from "@/router/pageNames";
import { defaultColumnsOrder, defaultColumnsVisibility } from "@/features/transportTableColumnsSettings/defaults";
import { migrateColumns, migrateOrder } from "@/features/transportTableColumnsSettings/migration";

const VERSION = 25;

export function loadColumnsOrder({ orgRole }) {
  const storageItem = localStorage.getItem(columnsOrderKey({ orgRole }));

  if (storageItem) {
    try {
      let orderSettings = JSON.parse(storageItem);
      if (orderSettings.version !== VERSION) {
        orderSettings = migrateOrder(orderSettings);
        saveColumnsOrder({ orgRole, order: orderSettings.order });
      }
      return orderSettings.order;
    } catch (e) {
      this.logger.warn("Could not load stored columns order. Falling back to default order", e);
    }
  }

  return defaultColumnsOrder({ orgRole });
}

export function loadColumnsVisibility({ orgRole, pageName }) {
  let storageItem = localStorage.getItem(columnsVisibilityKey({ orgRole, pageName }));

  if (!storageItem) {
    // Version 12 introduced a new key name to support saving settings based on orgRole.
    // We check if there are settings using the old key so we can migrate the settings.
    storageItem = localStorage.getItem(`settings.transportTable.${pageName}.columns`);
  }

  if (storageItem) {
    try {
      let columnSettings = JSON.parse(storageItem);
      if (columnSettings.version !== VERSION) {
        columnSettings = migrateColumns({ columnSettings, orgRole, pageName });
        saveColumnsVisibilityForPage({ pageName, orgRole, columns: columnSettings.columns });
      }
      return columnSettings.columns;
    } catch (e) {
      this.logger.warn("Could not load stored column settings. Falling back to default settings", e);
    }
  }

  return defaultColumnsVisibility({ orgRole, pageName });
}

export function saveColumnsOrder({ order, orgRole }) {
  localStorage.setItem(columnsOrderKey({ orgRole }), JSON.stringify({ order, version: VERSION }));
  return order;
}

export function saveColumnsVisibilityForPage({ columns, orgRole, pageName }) {
  localStorage.setItem(
    columnsVisibilityKey({ orgRole, pageName }),
    JSON.stringify({
      columns,
      version: VERSION,
    })
  );

  return columns;
}

export function saveColumnsVisibilityForAll({ newColumnsSettings, orgRole }) {
  const modifiedColumnSettings = {};

  // We check each page settings and only change the columns that the page
  // should have and avoid adding columns they shouldn't have.
  Object.values(TRANSPORT_PAGES).forEach((pageName) => {
    const modifiedColumns = loadColumnsVisibility({ orgRole, pageName }).map((columnSetting) => {
      const columnIndex = newColumnsSettings.findIndex((column) => column.key === columnSetting.key);
      if (columnIndex > -1) {
        columnSetting.enabled = newColumnsSettings[columnIndex].enabled;
      }
      return columnSetting;
    });

    saveColumnsVisibilityForPage({ columns: modifiedColumns, orgRole, pageName });

    modifiedColumnSettings[pageName] = modifiedColumns;
  });

  return modifiedColumnSettings;
}

function columnsOrderKey({ orgRole }) {
  return `settings.${orgRole}.transportsTable.order`;
}

function columnsVisibilityKey({ orgRole, pageName }) {
  return `settings.${orgRole}.transportsTable.${pageName}.columns`;
}

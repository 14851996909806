<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.33337 2.5H11.6667V17.5H8.33337V2.5Z"
      stroke="#A6B5C1"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 6.66663H18.3333V17.5H15V6.66663Z"
      stroke="#A6B5C1"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.66663 10.8334H4.99996V17.5H1.66663V10.8334Z"
      stroke="#A6B5C1"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: { type: String, value: "20" },
  },
};
</script>

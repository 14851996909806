import { getShippersWithTransports } from "@/features/shippers/api";

export const EVENT = {
  SHIPPERS_WITH_TRANSPORTS_REQUESTED: "SHIPPERS_WITH_TRANSPORTS_REQUESTED",
  REQUEST_SHIPPERS_WITH_TRANSPORTS_FAILED: "REQUEST_SHIPPERS_WITH_TRANSPORTS_FAILED",
  REQUEST_SHIPPERS_WITH_TRANSPORTS_SUCCEEDED: "REQUEST_SHIPPERS_WITH_TRANSPORTS_SUCCEEDED",
};

export const defaultState = () => ({
  shippersWithTransports: [],
  fetchingShippersWithTransports: false,
  fetchingShippersWithTransportsFailedError: null,
});

export const mutations = {
  [EVENT.SHIPPERS_WITH_TRANSPORTS_REQUESTED](state) {
    state.fetchingShippersWithTransports = true;
  },

  [EVENT.REQUEST_SHIPPERS_WITH_TRANSPORTS_SUCCEEDED](state, response) {
    state.fetchingShippersWithTransportsFailedError = null;
    state.shippersWithTransports = response;
    state.fetchingShippersWithTransports = false;
  },

  [EVENT.REQUEST_SHIPPERS_WITH_TRANSPORTS_FAILED](state, error) {
    state.fetchingShippersWithTransports = false;
    state.fetchingShippersWithTransportsFailedError = error;
  },
};

export const actions = ({ getShippersWithTransports }) => ({
  async fetchShippersWithTransports({ commit }) {
    commit(EVENT.SHIPPERS_WITH_TRANSPORTS_REQUESTED);
    try {
      const response = await getShippersWithTransports();
      commit(EVENT.REQUEST_SHIPPERS_WITH_TRANSPORTS_SUCCEEDED, response);
    } catch (error) {
      commit(EVENT.REQUEST_SHIPPERS_WITH_TRANSPORTS_FAILED, error);
    }
  },
});

/**
 * Builds a store module for storing transport shippers.
 * @returns {Object} - A Vuex module.
 */
export function buildShippersModule() {
  return {
    namespaced: true,

    state: defaultState(),

    mutations,

    actions: actions({ getShippersWithTransports }),

    getters: {},
  };
}

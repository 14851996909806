<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3333 3.33334H15C15.9205 3.33334 16.6667 4.07954 16.6667 5.00001V16.6667C16.6667 17.5872 15.9205 18.3333 15 18.3333H5.00001C4.07954 18.3333 3.33334 17.5872 3.33334 16.6667V5.00001C3.33334 4.07954 4.07954 3.33334 5.00001 3.33334H6.66668"
      stroke="#A6B5C1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.66666 2.66666C6.66666 2.11437 7.11437 1.66666 7.66666 1.66666H12.3333C12.8856 1.66666 13.3333 2.11437 13.3333 2.66666V3.99999C13.3333 4.55228 12.8856 4.99999 12.3333 4.99999H7.66666C7.11437 4.99999 6.66666 4.55227 6.66666 3.99999V2.66666Z"
      stroke="#A6B5C1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ReportingIcon",

  props: {
    size: { type: String, default: "100%" },
  },
};
</script>

<template>
  <div
    class="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle text-uppercase"
    :class="classObject"
  >
    <img v-if="url" class="rounded-circle h-100 w-100" :src="url" alt="" />
    <span v-else>{{ this.initials }}</span>
  </div>
</template>

<script>
export default {
  props: {
    name: { type: String, required: true },
    large: { type: Boolean, default: false },
    medium: { type: Boolean, default: false },
    url: String,
  },

  computed: {
    classObject() {
      return {
        large: this.large,
        medium: this.medium,
        "font-size-2xl": this.large,
      };
    },

    initials() {
      const parts = this.name.split(" ");
      return parts.length > 1 ? `${parts[0].substring(0, 1)}${parts[1].substring(0, 1)}` : this.name.substring(0, 2);
    },
  },
};
</script>

<style scoped>
div {
  height: 2rem;
  width: 2rem;
}

div.large {
  height: 3.75rem;
  width: 3.75rem;
}

div.medium {
  height: 2.5rem;
  width: 2.5rem;
}
</style>

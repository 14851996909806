import { getVersion } from "@/features/versionCheck/api";

const appVersion = process.env.VUE_APP_VERSION;

export const EVENT = {
  RESET_VERSION_TRIGGERED: "RESET_VERSION_TRIGGERED",
  VERSION_CHECK_FAILED: "VERSION_CHECK_FAILED",
  VERSION_CHECK_SUCCEEDED: "VERSION_CHECK_SUCCEEDED",
};

export const defaultState = (version = appVersion) => ({
  appVersion: version,
  error: null,
  serverVersion: version,
});

export const mutations = {
  [EVENT.RESET_VERSION_TRIGGERED](state) {
    Object.assign(state, defaultState(appVersion));
  },

  [EVENT.VERSION_CHECK_FAILED](state, error) {
    state.error = error;
  },

  [EVENT.VERSION_CHECK_SUCCEEDED](state, serverVersion) {
    state.serverVersion = serverVersion;
  },
};

export const actions = ({ getVersion }) => ({
  async getVersion({ commit }) {
    try {
      commit(EVENT.RESET_VERSION_TRIGGERED);
      const { version } = await getVersion();
      commit(EVENT.VERSION_CHECK_SUCCEEDED, version);
    } catch (error) {
      commit(EVENT.VERSION_CHECK_FAILED, error);
    }
  },
});

export const getters = {
  hasVersionMismatch(state) {
    return state.appVersion !== state.serverVersion;
  },
};

/**
 * Builds a store module for notifications.
 * @returns {Object} - A Vuex module.
 */
export function buildVersionCheckModule() {
  return {
    namespaced: true,

    state: defaultState(appVersion),

    mutations,

    actions: actions({ getVersion }),

    getters,
  };
}

import {
  defaultState as transportDefaultState,
  EVENT as TRANSPORTS_EVENT,
  mutations as transportMutations,
  actions as transportActions,
} from "@/features/transports/store";
import { addToWatchlist, getWatchedTransports, getWatchlist, removeFromWatchlist } from "@/features/watchlist/api";

export const EVENT = {
  ...TRANSPORTS_EVENT,
  WATCHLIST_FETCHED: "WATCHLIST_FETCHED",
  ADDED_TO_WATCHLIST: "ADDED_TO_WATCHLIST",
  REMOVED_FROM_WATCHLIST: "REMOVED_FROM_WATCHLIST",
};

export const defaultState = () => ({
  ...transportDefaultState(),
  watchlist: [],
});

export const mutations = {
  ...transportMutations,
  async [EVENT.WATCHLIST_FETCHED](state, transportIds) {
    state.watchlist = transportIds;
  },
  async [EVENT.ADDED_TO_WATCHLIST](state, transportId) {
    state.watchlist.push(transportId);
  },
  async [EVENT.REMOVED_FROM_WATCHLIST](state, transportId) {
    state.watchlist.splice(state.watchlist.indexOf(transportId), 1);

    const transportIndex = state.transports?.transports?.findIndex((transport) => transport.id === transportId);
    if (transportIndex > -1) {
      state.transports.transports.splice(transportIndex, 1);
      state.transports.totalElements--;
    }
  },
};

const extendedActions = transportActions({ requestTransports: getWatchedTransports });
export const actions = () => ({
  ...extendedActions,
  async getWatchlist({ commit }) {
    const watchlist = await getWatchlist();
    commit(EVENT.WATCHLIST_FETCHED, watchlist);
  },
  async addTransport(context, transportId) {
    await addToWatchlist(transportId);
    await extendedActions.fetch(context);
    context.commit(EVENT.ADDED_TO_WATCHLIST, transportId);
  },

  async removeTransport({ commit }, transportId) {
    await removeFromWatchlist(transportId);
    commit(EVENT.REMOVED_FROM_WATCHLIST, transportId);
  },
});

export const getters = () => ({
  watchlist(state) {
    return state.watchlist;
  },
});

/**
 * Builds a store module for the watchlist.
 * @returns {Object} - A Vuex module.
 */
export function buildWatchlistModule() {
  return {
    namespaced: true,
    state: defaultState(),
    mutations,
    actions: actions(),
    getters: getters(),
  };
}

/**
 * A mixin that adds a windowSize property to a component
 * with the height and width of the current window and
 * automatically updates the values as they change.
 *
 * Example:
 *
 * <script>
 *   mixins: [windowSizeMixin],
 *
 *   computed: {
 *     isWindowNarrow() {
 *       return this.windowSize.width < 640;
 *     }
 *   }
 * </script>
 */
export default {
  data() {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    windowSize() {
      return {
        height: this.windowHeight,
        width: this.windowWidth,
      };
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
  },
};

import Vue from "vue";

Vue.directive("clickOutside", {
  bind(element, binding, vnode) {
    element.clickOutsideEvent = (event) => {
      const isClickInside = element === event.target || element.contains(event.target);

      if (!isClickInside) {
        vnode.context[binding.expression](event); // invoke event handler
      }
    };

    document.body.addEventListener("click", element.clickOutsideEvent);
  },

  unbind(element) {
    document.body.removeEventListener("click", element.clickOutsideEvent);
  },
});

import {
  getNotifications,
  putAllNotificationsReadStatus,
  putNotificationReadStatus,
} from "@/features/notifications/api";

export const EVENT = {
  NOTIFICATIONS_RECEIVED: "NOTIFICATIONS_RECEIVED",
  NOTIFICATIONS_UPDATED: "NOTIFICATIONS_UPDATED",
  REQUEST_FAILED: "REQUEST_FAILED",
  REQUEST_STARTED: "REQUEST_STARTED",
  REQUEST_SUCCEEDED: "REQUEST_SUCCEEDED",
};

export const state = () => ({
  error: null,
  isRequestInProgress: false,
  notifications: [],
});

export const mutations = {
  [EVENT.NOTIFICATIONS_RECEIVED](state, notifications) {
    state.error = null;
    state.isRequestInProgress = false;
    state.notifications = notifications;
  },

  [EVENT.NOTIFICATIONS_UPDATED](state, notifications) {
    state.notifications = notifications;
  },

  [EVENT.REQUEST_FAILED](state, error) {
    state.isRequestInProgress = false;
    state.error = error;
  },

  [EVENT.REQUEST_STARTED](state) {
    state.isRequestInProgress = true;
  },

  [EVENT.REQUEST_SUCCEEDED](state) {
    state.error = null;
    state.isRequestInProgress = false;
  },
};

export const actions = ({ getNotifications, putAllNotificationsReadStatus, putNotificationReadStatus }) => ({
  async getNotifications({ commit }) {
    commit(EVENT.REQUEST_STARTED);

    try {
      const { notifications } = await getNotifications();

      commit(EVENT.NOTIFICATIONS_RECEIVED, notifications);
    } catch (error) {
      commit(EVENT.REQUEST_FAILED, error);
    }
  },

  async updateAllNotifications({ commit, state }, { isRead }) {
    commit(EVENT.REQUEST_STARTED);

    try {
      await putAllNotificationsReadStatus(isRead);
      commit(EVENT.REQUEST_SUCCEEDED);

      // Updating notifications in store to reflect the database
      // state until next polling update.
      const updatedNotifications = [...state.notifications];
      updatedNotifications.forEach((notification) => (notification.read = isRead));
      commit(EVENT.NOTIFICATIONS_UPDATED, updatedNotifications);
    } catch (error) {
      commit(EVENT.REQUEST_FAILED, error);
    }
  },

  async updateNotification({ commit, state }, { notificationId, isRead }) {
    commit(EVENT.REQUEST_STARTED);

    try {
      await putNotificationReadStatus({ notificationId, isRead });
      commit(EVENT.REQUEST_SUCCEEDED);

      // Updating notifications in store to reflect the database
      // state until next polling update.
      const updatedNotifications = [...state.notifications];
      updatedNotifications.find((notification) => notification.id === notificationId).read = isRead;
      commit(EVENT.NOTIFICATIONS_UPDATED, updatedNotifications);
    } catch (error) {
      commit(EVENT.REQUEST_FAILED, error);
    }
  },
});

export const getters = {
  hasUnreadNotifications(state) {
    return state.notifications.some((notification) => !notification.read);
  },
};

/**
 * Builds a store module for notifications.
 * @returns {Object} - A Vuex module.
 */
export function buildNotificationsModule() {
  return {
    namespaced: true,

    state: state(),

    mutations,

    actions: actions({ getNotifications, putAllNotificationsReadStatus, putNotificationReadStatus }),

    getters,
  };
}

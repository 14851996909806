/**
 * Module with helpers for working with the browser API.
 */

/**
 * Returns the CSS value of a custom property.
 * @param {string} name - The name of the property without the -- prefix.
 * @returns {string}
 */
export function getCustomCSSProperty(name) {
  return window.getComputedStyle(document.documentElement).getPropertyValue(`--${name}`);
}

export function updateQueryString(queryString) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set("q", queryString);
  history.pushState(null, "", `${window.location.pathname}?${searchParams.toString()}`);
}

<script>
import { mapGetters } from "vuex";

export default {
  name: "DisplayFor",

  props: {
    carrier: Boolean,
    shipper: Boolean,
  },

  computed: {
    ...mapGetters(["isUserCarrier", "isUserShipper"]),

    shouldRender() {
      if (this.shipper && this.isUserShipper) {
        return true;
      }

      if (this.carrier && this.isUserCarrier) {
        return true;
      }

      return false;
    },
  },

  render() {
    return this.shouldRender ? this.$slots.default : null;
  },
};
</script>

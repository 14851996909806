/**
 * Module for working with notifications.
 */

export const NOTIFICATION_CHANNEL = {
  EMAIL: "Email",
  WEB: "Web",
};

export const NOTIFICATION_TYPE = {
  TRANSPORT_APPROACHING_WAYPOINT: "TRANSPORT_APPROACHING_WAYPOINT",
  TRANSPORT_COMPLETED: "TRANSPORT_COMPLETED",
  TRANSPORT_DELAYED: "TRANSPORT_DELAYED",
  TRANSPORT_GPS_STATUS_LOW: "TRANSPORT_GPS_STATUS_LOW",
  TRANSPORT_GPS_STATUS_LOST: "TRANSPORT_GPS_STATUS_LOST",
  TRANSPORT_GPS_MISSING: "TRANSPORT_GPS_MISSING",
  TRANSPORT_TRACKING_ID_MISSING: "TRANSPORT_TRACKING_ID_MISSING",
};

import { PAGES } from "@/router/pageNames";
import store from "@/store";

const adminRoutes = [
  {
    path: "/admin",
    redirect: "/admin/transport-csv-import",
  },
  {
    path: "/admin/transport-csv-import",
    name: PAGES.ADMIN_TRANSPORT_CSV_IMPORT,
    component: () => import("./views/Admin.vue"),
    meta: { title: "admin.page-title" },
    beforeEnter: isAdminGuard,
  },
];

async function isAdminGuard(to, from, next) {
  if (await store.getters.isAdmin) {
    next();
    return;
  }

  next({ name: PAGES.NOT_FOUND });
}

export default adminRoutes;

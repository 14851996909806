"use strict";
import StackdriverErrorReporter from "stackdriver-errors-js";

export default class Logger {
  private static instance: Logger;
  private readonly errorReporter: StackdriverErrorReporter;
  private readonly projectId: string = process.env.VUE_APP_CLOUD_ERROR_REPORTING_PROJECT_ID;
  private readonly apiKey: string = process.env.VUE_APP_CLOUD_ERROR_REPORTING_API_KEY;

  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  constructor() {
    this.errorReporter = new StackdriverErrorReporter();
    if (this.projectId && this.apiKey) {
      this.errorReporter.start({
        key: this.apiKey,
        projectId: this.projectId,
        service: "browser",
      });
    }
  }

  public error(message, ...args): void {
    console.error(message, ...args); // eslint-disable-line no-console
    this.sendError(message);
  }

  public warn(message, ...args): void {
    console.warn(message, ...args); // eslint-disable-line no-console
    // TODO: log to Google Cloud Logging API, use another logger: @google-cloud/logging or @google-cloud/logging-bunyan
  }

  private sendError(message): void {
    if (!this.errorReporter) {
      return;
    }

    if (["dev", "demo", "prod"].indexOf(process.env.NODE_ENV) !== -1) {
      this.errorReporter
        .report(message)
        .then(() => {
          console.debug("Error reported to cloud"); // eslint-disable-line no-console
        })
        .catch((e) => {
          console.warn("Error reporting to cloud failed", e); // eslint-disable-line no-console
        });
    }
  }
}

<template>
  <div
    class="menu-area bg-dark-blue-600 flex-shrink-0 p-sm-2 d-flex align-items-center transition-ease-out"
    :class="classObject"
  >
    <button class="btn text-white" :class="{ invisible: hideButton }" @click="toggle">
      <Icon :type="iconType" size="24" />
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Icon from "@/components/Icons/Icon";
import windowSizeMixin from "@/mixins/windowSizeMixin";

export default {
  name: "NavMenu",

  components: {
    Icon,
  },

  mixins: [windowSizeMixin],

  computed: {
    hideButton() {
      return this.windowSize.width >= 1280;
    },

    ...mapState({
      iconType(state) {
        return state.ui.showNavMenu && this.windowSize.width < 640 ? "x" : "menu";
      },

      classObject(state) {
        return {
          "w-100": state.ui.showNavMenu && this.windowSize.width < 640,
          "justify-content-around": this.windowSize.width >= 640,
          "justify-content-start": this.windowSize.width < 640,
        };
      },
    }),
  },

  methods: {
    toggle() {
      this.$store.dispatch("toggleNavMenu");
    },
  },
};
</script>

<style scoped>
.menu-area {
  padding: 0.3125rem;
  transition-property: width;
  width: 3.5rem;
}

@media (min-width: 1280px) {
  .menu-area {
    width: 17rem;
  }
}
</style>

import { buildQueryParams, getHttpClient } from "@/core/api";

export const FILTER_PARAMETERS = {
  CARRIERS: "carriers",
  SHIPPERS: "shippers",
  ETA_STATUS: "etaStatus",
  FROM: "from",
  GPS: "gps_status",
  REFERENCE: "reference",
  SCHEDULED: "scheduled",
  TO: "to",
  TRACKING_ID: "trackingId",
  NEXT_WAYPOINT: "nextWaypoint",
  PHASE: "phase",
};

export const SORT_BY = {
  ARRIVED: "ARRIVED",
  CANCELLED_AT: "CANCELLED_AT",
  ETA_STATUS: "ETA",
  FROM: "FROM",
  REFERENCE: "REFERENCE",
  SCHEDULED: "SCHEDULED",
  TRACKING_ID: "TRACKING_ID",
  NEXT_ETA: "NEXT_WP_ETA",
  NEXT_SCHEDULED: "NEXT_WP_PTA",
};

export const SORT_BY_DIRECTION = {
  ASCENDING: "asc",
  DESCENDING: "desc",
};

export async function cancelTransport(transportId) {
  const response = await getHttpClient().post(`v2/transports/${transportId}/cancel`, {});
  return response.data;
}

export async function getActionRequiredTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/action-required", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getAreaArrivedTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/completed/area-arrived", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getArrivedTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/completed/arrived", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getCancelledTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/cancelled", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getCompletedTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/completed", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getGpsLostTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/action-required/gps-lost", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getGpsLowTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/action-required/gps-low", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getGpsMissingTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/action-required/gps-missing", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getIncomingTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/incoming", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getPresumablyIncomingTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/presumably-incoming", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getManuallyCompletedTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/completed/completed-manually", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getOngoingTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/ongoing", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getPresumablyOngoingTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/presumably-ongoing", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getOverdueTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/completed/overdue", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getWaypointConfirmations(queryParameters) {
  const response = await getHttpClient().get(`v2/transports/action-required/waypoint-confirmations`, {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getPlannedTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/planned", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getTrackingIdMissingTransports(queryParameters) {
  const response = await getHttpClient().get("v2/transports/action-required/tracking-id-missing", {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function getTransportById({ transportId, includeCancelled = false }) {
  const response = await getHttpClient().get(`v2/transports/${transportId}`, {
    params: includeCancelled ? { includeCancelled: true } : null,
  });

  return response.data;
}

export async function getTransportGpsSignals(transportId) {
  const response = await getHttpClient().get(`v2/transports/${transportId}/gps-signals`);
  return response.data;
}

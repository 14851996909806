/**
 * Module that deals with transports and their data.
 **/

import {
  areEqual,
  convertUtcToTimeZone,
  formatDate,
  formatDateRange,
  formatDateTime,
  formatLongDate,
  getBrowserTimeZone,
  isSameDay,
  differenceInHours,
} from "@/core/date";
import { ORG_ROLE } from "./organization";

export const ETA_STATES = {
  DELAYED: "DELAYED",
  EARLY: "EARLY",
  ON_TIME: "ON_TIME",
};

export const GPS_SIGNAL_STATUS = {
  TRACKING_ID_MISSING: "TRACKING_ID_MISSING",
  GPS_SIGNAL_MISSING: "GPS_SIGNAL_MISSING",
  GPS_SIGNAL_LOST: "GPS_SIGNAL_LOST",
  GPS_SIGNAL_LOW: "GPS_SIGNAL_LOW",
  GPS_SIGNAL_OK: "GPS_SIGNAL_OK",
};

export const MAX_WAYPOINT_FROM_DATE_AGE_IN_DAYS = 7;

export const TRANSPORT_COMPLETION_REASONS = {
  AREA_ARRIVED: "AREA_ARRIVED",
  ARRIVED: "ARRIVED",
  CANCELED: "CANCELED",
  MANUALLY_COMPLETED: "MANUALLY_COMPLETED",
  OVERDUE: "OVERDUE",
};

export const TRANSPORT_STATES = {
  CANCELLED: "CANCELLED",
  COMPLETED: "COMPLETED",
  INCOMING: "INCOMING",
  ONGOING: "ONGOING",
  PLANNED: "PLANNED",
  PRESUMABLY_INCOMING: "PRESUMABLY_INCOMING",
  PRESUMABLY_ONGOING: "PRESUMABLY_ONGOING",
};

export const WAYPOINT_STATES = {
  APPROACHING: "APPROACHING",
  AREA_ENTERED: "AREA_ENTERED",
  COMPLETED: "COMPLETED",
  OPEN: "OPEN",
  STARTED: "STARTED",
};

export const WAYPOINT_TRANSITION_REASONS = {
  ENTERED_GEOFENCE_1: "ENTERED_GEOFENCE_1",
  ENTERED_GEOFENCE_2: "ENTERED_GEOFENCE_2",
  ENTERED_GEOFENCE_4: "ENTERED_GEOFENCE_4",
  LEFT_GEOFENCE_3: "LEFT_GEOFENCE_3",
  LEFT_PREVIOUS_GEOFENCE_1: "LEFT_PREVIOUS_GEOFENCE_1",
  MANUALLY: "MANUALLY",
  OVERDUE: "OVERDUE",
  RESET: "RESET",
  WITHIN_TRACKING_TIMEFRAME: "WITHIN_TRACKING_TIMEFRAME",
  BY_SUBSEQUENT_WAYPOINT: "BY_SUBSEQUENT_WAYPOINT",
};

export const WAYPOINT_TYPE = {
  APPOINTMENT: "APPOINTMENT",
  RANGE: "RANGE",
};

export function formatETATime({ eta, locale, timeZone, todayPrefix, includeTimeZone } = {}, now = new Date()) {
  if (!eta) {
    return "";
  }

  const todayFormatter = (date) => `${todayPrefix ? todayPrefix + " " : ""}${formatDate(date, "p", locale)}`;

  const { max, min } = eta;

  const tz = timeZone || getBrowserTimeZone();

  const earlyDate = convertUtcToTimeZone(min, tz);
  const lateDate = convertUtcToTimeZone(max, tz);

  const isNowSameDayAsEarlyDay = isSameDay(now, earlyDate);
  const isEarlySameDayAsLate = isSameDay(earlyDate, lateDate);

  const earlyDateFormatter = isNowSameDayAsEarlyDay ? todayFormatter : formatLongDate;
  const lateDateFormatter = isEarlySameDayAsLate ? formatDateTime : formatLongDate;

  const formattedEarlyDate = earlyDateFormatter(earlyDate, locale, tz);
  const formattedLateDate = lateDateFormatter(lateDate, locale, tz);

  const formattedETA = areEqual(earlyDate, lateDate)
    ? formattedEarlyDate
    : `${formattedEarlyDate} - ${formattedLateDate}`;

  return includeTimeZone ? `${formattedETA} (${tz})` : formattedETA;
}

export function formatLatestGpsSignal(transport, locale, timeZone = getBrowserTimeZone()) {
  if (!transport?.latestGpsSignal) {
    return "";
  }

  const date = convertUtcToTimeZone(transport.latestGpsSignal, timeZone);
  return formatLongDate(date, locale, timeZone);
}

export function formatLastEtaCalculationDate(transport, locale, timeZone) {
  if (!transport?.etaCalculationTimestamp) {
    return "";
  }

  const date = convertUtcToTimeZone(transport.etaCalculationTimestamp, timeZone);

  return formatLongDate(date, locale, timeZone);
}

export function formatTransportATA({ transport, locale, timeZone, useWaypointTime }) {
  const lastWaypoint = getLastWaypoint(transport);

  if (lastWaypoint && lastWaypoint.ata) {
    return formatWaypointATA({ waypoint: lastWaypoint, locale, timeZone, useWaypointTime });
  }

  return "";
}

export function formatTransportETA({ locale, todayPrefix, transport, unitTestTimeZone, useWaypointTime }) {
  if (!transport) {
    return "";
  }
  const waypoint = getLastWaypoint(transport);
  return formatWaypointETA({ locale, todayPrefix, waypoint, unitTestTimeZone, useWaypointTime });
}

export function formatNextWaypointETA({ locale, todayPrefix, transport, unitTestTimeZone, useWaypointTime }) {
  if (!transport) {
    return "";
  }
  const waypoint = getNextWaypoint(transport);
  return formatWaypointETA({ locale, todayPrefix, waypoint, unitTestTimeZone, useWaypointTime });
}

export function formatWaypointETA({ locale, todayPrefix, waypoint, unitTestTimeZone, useWaypointTime }) {
  if (waypoint && waypoint.eta) {
    const { eta, timezone } = waypoint;
    // The unitTestTimeZone parameter only exists to enable unit tests to run on GitHub.
    const tz = useWaypointTime ? timezone : unitTestTimeZone;

    return formatETATime({ eta, locale, todayPrefix, timeZone: tz, includeTimeZone: useWaypointTime });
  }

  return "";
}

export function formatTransportPTA({ transport, locale, timeZone, useWaypointTime }) {
  if (!transport) {
    return "";
  }

  const lastWaypoint = getLastWaypoint(transport);

  if (lastWaypoint && lastWaypoint.plannedTimeOfArrival) {
    // The timeZone parameter only exists to enable unit tests to run on GitHub.
    return formatWaypointPTA({ waypoint: lastWaypoint, locale, timeZone, useWaypointTime });
  }

  return "";
}

export function formatNextWaypointPTA({ transport, locale, timeZone, useWaypointTime }) {
  if (!transport) {
    return "";
  }
  const nextWaypoint = getNextWaypoint(transport);
  if (nextWaypoint && nextWaypoint.plannedTimeOfArrival) {
    // The timeZone parameter only exists to enable unit tests to run on GitHub.
    return formatWaypointPTA({ waypoint: nextWaypoint, locale, timeZone, useWaypointTime });
  }
  return "";
}

export function formatWaypointAddress(waypoint) {
  if (!waypoint) {
    return "";
  }

  let { city = "", country = "", postalCode = "", state = "" } = waypoint;

  postalCode = postalCode ? postalCode.substr(0, 2) : "";

  return [city, state, postalCode, country].filter((value) => Boolean(value)).join(", ");
}

export function formatWaypointLocation(waypoint) {
  if (!waypoint) {
    return "";
  }

  const { city = "", countryCode = "", postalCode = "" } = waypoint;
  return [countryCode, postalCode.slice(0, 2), city].join(" ").trim().replace("  ", " ");
}

export function formatWaypointATA({ waypoint, locale, timeZone, useWaypointTime }) {
  const { ata, timezone } = waypoint;
  // The timeZone parameter is only here to enable unit tests to run on GitHub.
  const tz = useWaypointTime ? timezone : timeZone || getBrowserTimeZone();

  const date = convertUtcToTimeZone(ata, tz);

  return useWaypointTime ? `${formatLongDate(date, locale, tz)} (${tz})` : formatLongDate(date, locale, tz);
}

export function formatWaypointPTA({ waypoint, locale, timeZone, useWaypointTime }) {
  const { plannedTimeOfArrival, timezone } = waypoint;
  // The timeZone parameter is only here to enable unit tests to run on GitHub.
  const tz = useWaypointTime ? timezone : timeZone || getBrowserTimeZone();

  const from = convertUtcToTimeZone(plannedTimeOfArrival.from, tz);
  const to = convertUtcToTimeZone(plannedTimeOfArrival.to, tz);

  return useWaypointTime
    ? `${formatDateRange({ from, to }, locale, tz)} (${tz})`
    : formatDateRange({ from, to }, locale, tz);
}

export function getFirstWaypoint(transport) {
  return (transport.waypoints || []).find((waypoint) => waypoint.order === 0);
}

export function getLastWaypoint(transport) {
  const waypoints = transport.waypoints || [];
  const result = waypoints.find((waypoint) => waypoint.order === waypoints.length - 1);

  return result || waypoints[waypoints.length - 1];
}

export function getNextWaypoint(transport) {
  return (transport.waypoints || []).find((waypoint) => waypoint.status !== WAYPOINT_STATES.COMPLETED);
}

export function getWaypointStates(transport) {
  return (transport.waypoints || []).map((waypoint) => waypoint.status);
}

export function hasWaypointsNeedingConfirmation(transport) {
  return (transport.waypoints || []).some((waypoint) => waypoint.confirmationNeeded);
}

export function isActionRequired({ orgRole, transport }) {
  if (isTransportCancelled(transport) || isTransportCompleted(transport) || isTransportPlanned(transport)) {
    return false;
  }

  if (
    isGpsSignalLost(transport) ||
    isGpsSignalLow(transport) ||
    isGpsSignalMissing(transport) ||
    isTrackingIdMissing(transport)
  ) {
    return true;
  }

  return orgRole === ORG_ROLE.SHIPPER && hasWaypointsNeedingConfirmation(transport);
}

export function isFirstWaypoint(waypoint, waypoints = []) {
  if (!waypoints.length) {
    return false;
  }

  return waypoint.order === 0;
}

export function isGpsSignalLost(transport) {
  return transport?.gpsSignalStatus === GPS_SIGNAL_STATUS.GPS_SIGNAL_LOST;
}

export function isGpsSignalLow(transport) {
  return transport?.gpsSignalStatus === GPS_SIGNAL_STATUS.GPS_SIGNAL_LOW;
}

export function isGpsSignalMissing(transport) {
  return transport?.gpsSignalStatus === GPS_SIGNAL_STATUS.GPS_SIGNAL_MISSING;
}

export function isGpsSignalOk(transport) {
  return transport?.gpsSignalStatus === GPS_SIGNAL_STATUS.GPS_SIGNAL_OK;
}

export function isLastWaypoint(waypoint, waypoints = []) {
  if (!waypoints.length) {
    return false;
  }

  return waypoint.order === waypoints.length - 1;
}

export function isNextWaypoint(waypoint, waypoints = []) {
  if (!waypoints.length) {
    return false;
  }

  const previousWaypoint = waypoints.find((w) => w.order === waypoint.order - 1);
  const nextWaypoint = waypoints.find((w) => w.order === waypoint.order + 1);
  const isPreviousWaypointCompleted = !previousWaypoint || previousWaypoint.status === WAYPOINT_STATES.COMPLETED;
  const isNextWaypointOpen = !nextWaypoint || nextWaypoint.status === WAYPOINT_STATES.OPEN;

  return isPreviousWaypointCompleted && isNextWaypointOpen;
}

export function isPresumablyIncoming(transport) {
  return transport.status === TRANSPORT_STATES.PRESUMABLY_INCOMING;
}

export function isPresumablyOngoing(transport) {
  return transport.status === TRANSPORT_STATES.PRESUMABLY_ONGOING;
}

export function isTrackingIdMissing(transport) {
  return transport.gpsSignalStatus === GPS_SIGNAL_STATUS.TRACKING_ID_MISSING;
}

export function isTrackingIdPending(transport) {
  return !transport.trackingId && !isTrackingIdMissing(transport);
}

export function isTransportCancelled(transport) {
  return transport.status === TRANSPORT_STATES.CANCELLED;
}

export function isTransportCompleted(transport) {
  return transport.status === TRANSPORT_STATES.COMPLETED;
}

export function isTransportCompletedOverdue(transport) {
  return (
    transport.status === TRANSPORT_STATES.COMPLETED &&
    transport.completionReason === TRANSPORT_COMPLETION_REASONS.OVERDUE
  );
}

export function isTransportIncoming(transport) {
  return transport.status === TRANSPORT_STATES.INCOMING;
}

export function isTransportOngoing(transport) {
  return transport.status === TRANSPORT_STATES.ONGOING;
}

export function isTransportPlanned(transport) {
  return transport.status === TRANSPORT_STATES.PLANNED;
}

export function isWaypointApproaching(waypoint) {
  return waypoint.status === WAYPOINT_STATES.APPROACHING;
}

export function isWaypointAreaEntered(waypoint) {
  return waypoint.status === WAYPOINT_STATES.AREA_ENTERED;
}

export function isWaypointCompleted(waypoint) {
  return waypoint.status === WAYPOINT_STATES.COMPLETED;
}

export function isWaypointETADelayed(waypoint) {
  return waypoint.eta?.status === ETA_STATES.DELAYED;
}

export function isWaypointETAEarly(waypoint) {
  return waypoint.eta?.status === ETA_STATES.EARLY;
}

export function isWaypointETAOnTime(waypoint) {
  return waypoint.eta?.status === ETA_STATES.ON_TIME;
}

export function isWaypointOpen(waypoint) {
  return waypoint.status === WAYPOINT_STATES.OPEN;
}

export function isWaypointStarted(waypoint) {
  return waypoint.status === WAYPOINT_STATES.STARTED;
}

export function isETACalculationOutdated(tracking) {
  if (!tracking || !tracking.latestEtaCalculationTimestamp) {
    return false;
  }

  const now = new Date();
  const localTimestamp = convertUtcToTimeZone(tracking.latestEtaCalculationTimestamp, getBrowserTimeZone());

  return differenceInHours(now, localTimestamp) >= 5;
}

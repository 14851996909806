<template>
  <div>
    <SiteHeader />

    <Navigation />

    <main class="header-space transition-ease-out vh-100" :class="{ 'nav-space': isNavMenuOpen }">
      <router-view />
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
import windowSizeMixin from "@/mixins/windowSizeMixin";

import Navigation from "@/components/Navigation";
import SiteHeader from "@/components/SiteHeader/SiteHeader";

export default {
  components: {
    Navigation,
    SiteHeader,
  },

  mixins: [windowSizeMixin],

  computed: {
    ...mapState({
      isNavMenuOpen(state) {
        if (this.windowSize.width >= 1280) {
          return true;
        }

        return state.ui.showNavMenu;
      },
    }),
  },
};
</script>

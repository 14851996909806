<template>
  <b-toast :id="id" :no-auto-hide="noAutoHide" toaster="b-toaster-bottom-right" :variant="variant">
    <template #toast-title>
      <div>
        <div class="mb-3 d-flex align-items-start">
          <Icon :type="iconType" size="20" />
          <span class="ml-2 mr-3 font-weight-bold">{{ title }}</span>
        </div>
        <p class="ml-5">
          <slot></slot>
        </p>
      </div>
    </template>
  </b-toast>
</template>

<script>
import Icon from "@/components/Icons/Icon";
export default {
  name: "Toast",

  components: { Icon },

  props: {
    id: { type: String, required: true },
    noAutoHide: Boolean,
    title: { type: String, required: true },
    variant: {
      type: String,
      validator(value) {
        return ["", "danger", "info", "success", "warning"].includes(value);
      },
    },
  },

  computed: {
    iconType() {
      switch (this.variant) {
        case "danger":
        case "info":
          return "alert-circle";
        case "success":
          return "check-circle";
        case "warning":
          return "alert-triangle";
        default:
          return "alert-circle";
      }
    },
  },
};
</script>

import { buildQueryParams, getHttpClient } from "@/core/api";

export async function getLocationArrivalTransports(locationArrivalId, queryParameters) {
  const response = await getHttpClient().get(`v2/locations/${locationArrivalId}/arrivals`, {
    params: buildQueryParams(queryParameters),
  });

  return response.data;
}

export async function postLocationArrival(locationArrival) {
  const response = await getHttpClient().post(`v2/locations`, locationArrival);
  return response.data;
}

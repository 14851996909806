<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.95909 9.21591C3.90037 9.02813 3.86824 8.83305 3.86364 8.63636V5.90909C3.86364 3.96591 6.79545 2.5 10.6818 2.5C14.5682 2.5 17.5 3.96591 17.5 5.90909V8.63636C17.4624 9.0906 17.3084 9.52748 17.0528 9.90487C16.7973 10.2823 16.4487 10.5874 16.0409 10.7909C16.1035 10.9754 16.1357 11.1688 16.1364 11.3636V14.0909C16.1364 16.0341 13.2045 17.5 9.31818 17.5C5.43182 17.5 2.5 16.0341 2.5 14.0909V11.3636C2.53677 10.91 2.69052 10.4737 2.94625 10.0973C3.20198 9.72089 3.55095 9.4172 3.95909 9.21591V9.21591ZM10.6818 12.0455C8.05682 12.0455 5.86818 11.3636 4.72273 10.3409C4.17727 10.6682 3.86364 11.0227 3.86364 11.3636C3.86364 12.1955 5.99091 13.4091 9.31818 13.4091C12.6455 13.4091 14.7114 12.2295 14.7727 11.3977C13.4564 11.8471 12.0725 12.0662 10.6818 12.0455V12.0455ZM10.6818 3.86364C7.35455 3.86364 5.22727 5.07727 5.22727 5.90909C5.22727 6.74091 7.35455 7.95455 10.6818 7.95455C14.0091 7.95455 16.1364 6.74091 16.1364 5.90909C16.1364 5.07727 14.0091 3.86364 10.6818 3.86364ZM16.1364 7.99545C14.4781 8.9388 12.588 9.39713 10.6818 9.31818C8.77561 9.39713 6.88558 8.9388 5.22727 7.99545V8.63636C5.22727 9.46818 7.35455 10.6818 10.6818 10.6818C14.0091 10.6818 16.1364 9.46818 16.1364 8.63636V7.99545ZM9.31818 16.1364C12.6455 16.1364 14.7727 14.9227 14.7727 14.0909V13.45C13.1144 14.3933 11.2244 14.8517 9.31818 14.7727C7.41197 14.8517 5.52194 14.3933 3.86364 13.45V14.0909C3.86364 14.9227 5.99091 16.1364 9.31818 16.1364Z"
      fill="#A6B5C1"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: { type: String, value: "20" },
  },
};
</script>

import { getAuthClient, isTokenExpired } from "@/features/auth";

const LOCAL_STORAGE_KEY = "authTokens";

export const EVENT = {
  USER_LOGGED_IN: "USER_LOGGED_IN",
  USER_LOGGED_OUT: "USER_LOGGED_OUT",
};

export const defaultState = () => ({
  authTokens: loadAuthTokens(),
});

export const mutations = {
  [EVENT.USER_LOGGED_IN](state, authTokens) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(authTokens));
    state.authTokens = authTokens;
  },

  [EVENT.USER_LOGGED_OUT](state) {
    localStorage.setItem(LOCAL_STORAGE_KEY, null);
    state.authTokens = null;
  },
};

export const actions = ({ getAuthClient, window }) => ({
  login({ commit }, authTokens) {
    commit(EVENT.USER_LOGGED_IN, authTokens);
  },

  async logout({ commit }) {
    commit(EVENT.USER_LOGGED_OUT);
    const client = await getAuthClient();

    // clears Auth0 cookies and redirect to "/"
    // which is guarded with "authGuard" leading to a redirection to auth0 login page
    client.logout({
      returnTo: window.location.origin,
    });
  },
});

export const getters = ({ isTokenExpired }) => ({
  accessToken(state) {
    return state.authTokens?.accessToken;
  },

  idToken(state) {
    return state.authTokens?.idToken;
  },

  isAuthenticated(state) {
    return Boolean(state.authTokens);
  },

  isTokenExpired(state) {
    const accessToken = state.authTokens?.accessToken;
    if (accessToken) {
      return isTokenExpired(accessToken);
    }

    return true;
  },
});

/**
 * Builds a store module of authentication.
 * @returns {Object} - A Vuex module.
 */
export function buildAuthModule() {
  return {
    namespaced: true,

    state: defaultState(),

    mutations,

    actions: actions({ getAuthClient, window }),

    getters: getters({ isTokenExpired }),
  };
}

function loadAuthTokens() {
  try {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  } catch {
    return null;
  }
}

import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return { messages, locales };
}

const { messages, locales } = loadLocaleMessages();

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: messages,
});

export default i18n;

export function useI18nHotReloading() {
  if (module.hot) {
    module.hot.accept(locales.id, () => {
      const { messages: newMessages } = loadLocaleMessages();

      Object.keys(newMessages)
        .filter((locale) => messages[locale] !== newMessages[locale])
        .forEach((locale) => {
          messages[locale] = newMessages[locale];
          i18n.setLocaleMessage(locale, messages[locale]);
        });
    });
  }
}

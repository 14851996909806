<template>
  <b-dropdown
    toggle-class="text-decoration-none"
    menu-class="bg-primary text-white context-menu"
    no-caret
    class="context-menu-button"
    :dropleft="dropleft"
    :dropright="dropright"
    :left="left"
    :right="right"
  >
    <template v-slot:button-content>
      <Icon type="more-vertical" :size="iconSize"></Icon>
    </template>

    <slot></slot>
  </b-dropdown>
</template>

<script>
import Icon from "@/components/Icons/Icon";
export default {
  components: { Icon },

  props: {
    dropleft: Boolean,
    dropright: Boolean,
    iconSize: { type: String, default: "22" },
    left: Boolean,
    right: Boolean,
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.context-menu) {
  padding: 0;
  border: none;
  z-index: $zindex-dropdown;

  li .dropdown-item {
    border-radius: 0;
  }

  li:first-child .dropdown-item {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  li:last-child .dropdown-item {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  a.dropdown-item,
  button.dropdown-item {
    color: $white;
    font-weight: $btn-font-weight;
    padding: map-get($spacers, 2) map-get($spacers, 3);
    &:hover,
    &:active,
    &:focus {
      background: $mid-blue-500;
    }
  }
}
</style>

<template>
  <Toast no-auto-hide id="version-message" :title="$t('version-check.message.title')">
    <slot>
      <p v-html="$t('version-check.message.text')"></p>
      <b-button variant="primary" @click="onClick">{{ $t("version-check.button-text") }}</b-button>
    </slot>
  </Toast>
</template>

<script>
import Toast from "@/components/Toasts/Toast";

export default {
  name: "VersionToast",

  components: {
    Toast,
  },

  methods: {
    onClick() {
      window.location.reload();
    },
  },
};
</script>

import { getHttpClient } from "@/core/api";

export async function getNotifications() {
  const response = await getHttpClient().get("v2/notifications");
  return response.data;
}

export async function getNotificationsSettings() {
  const response = await getHttpClient().get("v2/notifications/settings");
  return response.data;
}

export async function putAllNotificationsReadStatus(isRead) {
  const response = await getHttpClient().put(`v2/notifications`, { read: isRead });

  return response.data;
}

export async function putNotificationReadStatus({ notificationId, isRead }) {
  const response = await getHttpClient().put(`v2/notifications/${notificationId}`, { read: isRead });

  return response.data;
}

export async function putNotificationsSettings(settings) {
  const response = await getHttpClient().put("v2/notifications/settings", settings);
  return response.data;
}
